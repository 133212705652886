import * as React from 'react';
import dynamic from 'next/dynamic';

import {
  RatingModalSetterType,
  RatingModalStateType,
} from './RatingModalContext';

const DynamicRatingModal = dynamic(() => import('./RatingModal'));

type RatingModalDynaType = RatingModalStateType & RatingModalSetterType;
const RatingModalDyna = (props: RatingModalDynaType): JSX.Element => {
  const { ratingModalOpen, ratingModalEdit } = props;

  if (ratingModalOpen || ratingModalEdit) {
    return <DynamicRatingModal {...props} />;
  }
  return <></>;
};
export default React.memo(RatingModalDyna);
