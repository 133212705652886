import { Caffe, CaffePromise } from 'apiclient';
import { AxiosRequestConfig } from 'axios';
import { CartData } from 'types/cart';

const caffe = new Caffe();

export interface getCartProps {
  axiosConfig?: AxiosRequestConfig;
}

export type getCartReturn = CartData;

const getCart = async (props: getCartProps): CaffePromise<getCartReturn> => {
  // will use cart_id from cookies and session user
  const { axiosConfig } = props;
  return caffe.request({
    ...{
      method: 'GET',
      url: '/api/method/caff.e.cart.get_cart',
      params: props,
      withCredentials: true,
    },
    ...axiosConfig,
  });
};
export default getCart;
