import * as React from 'react';
import { Box } from '@mui/material';

import { UpDownRightLeft } from 'assets/Icon';

const BackToTop = (): JSX.Element => {
  // set to 50 to account for 2 line announcement
  // 24px [Text] + (8px * 2) [Margin]
  const minBottomPx = 50;
  const baseBottomPxWhenReachBottomPx = 200;
  const [showScroll, setShowScroll] = React.useState(false);
  const [scrollBottomPosition, setScrollBottomPosition] = React.useState(
    `${minBottomPx}px`,
  );

  const checkScrollTop = () => {
    // show button if scroll down more than half of viewing screen
    const showThreshold = window.innerHeight / 2;
    if (!showScroll && window.pageYOffset > showThreshold) {
      setShowScroll(true);
    } else if (showScroll && window.pageYOffset <= showThreshold) {
      setShowScroll(false);
    }

    const positionFromPageBottom =
      document.body.offsetHeight - (window.innerHeight + window.pageYOffset);

    const footerHeight = document.getElementById(
      'bottom-footer-container',
    )?.offsetHeight;
    const bottomPxWhenReachBottomPx =
      footerHeight || baseBottomPxWhenReachBottomPx;
    // 36 from btn it self, 18 from logo top margin, 16 (~2 theme space) from footer top padding
    const bottomOffset = 36 + 18 + 16;

    if (positionFromPageBottom < bottomPxWhenReachBottomPx - bottomOffset) {
      // 10px = 16px from tagline margin bottom [1rem] - 6px from buttom itself
      const scrollBottomPositionPx =
        bottomPxWhenReachBottomPx - positionFromPageBottom - bottomOffset;
      setScrollBottomPosition(`${scrollBottomPositionPx}px`);
    } else {
      setScrollBottomPosition(`${minBottomPx}px`);
    }
  };

  const scrollTop = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  // https://dev.to/vvo/how-to-solve-window-is-not-defined-errors-in-react-and-next-js-5f97
  React.useEffect(() => {
    window.addEventListener('scroll', checkScrollTop);
    return () => {
      window.removeEventListener('scroll', checkScrollTop);
    };
  });

  return (
    <Box
      onClick={scrollTop}
      sx={{
        display: showScroll ? 'flex' : 'none',
        color: 'common.grey',
        position: 'fixed',
        bottom: scrollBottomPosition,
        right: '0.5rem',
        backgroundColor: 'common.lightGrey',
        zIndex: 95,
        cursor: 'pointer',
        animation: 'fadeIn 0.3s',
        transition: 'opacity 0.4s',
        width: '36px',
        height: '36px',
        borderRadius: '18px',
        boxShadow:
          '0px 3px 1px -2px rgb(0 0 0 / 11%), 0px 2px 2px 0px rgb(0 0 0 / 7%), 0px 1px 5px 0px rgb(0 0 0 / 7%)',
      }}
    >
      <Box sx={{ margin: 'auto' }}>
        <UpDownRightLeft />
      </Box>
    </Box>
  );
};
export default BackToTop;
