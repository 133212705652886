/* eslint-disable camelcase */
export const parseServerMsg = (
  msg: string,
): {
  message: string;
  title: string;
  indicator: string;
  raise_exception: string;
} => {
  let msgObj = JSON.parse(msg)[0];
  msgObj = JSON.parse(msgObj);
  return msgObj;
};
