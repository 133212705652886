import * as React from 'react';

import { readReviewReturn } from 'api/review/readReview';

export type RatingModalOpenContext = {
  shopSlug: string;
  productSlug: string;
  productCodeFull: string;
  defaultVal?: readReviewReturn;
  onReviewSubmit?: () => void;
};

export type RatingModalEditContext = {
  shopSlug: string;
  productSlug: string;
  productCodeFull: string;
  name: string;
  onReviewSubmit?: () => void;
};

export type RatingModalStateType = {
  ratingModalOpen: RatingModalOpenContext | null;
  ratingModalEdit: RatingModalEditContext | null;
};

export type RatingModalSetterType = {
  setRatingModalOpen: React.Dispatch<
    React.SetStateAction<RatingModalOpenContext | null>
  >;
  setRatingModalEdit: React.Dispatch<
    React.SetStateAction<RatingModalEditContext | null>
  >;
};

export const defaultRatingModalSetter = {
  setRatingModalOpen: (): null => null,
  setRatingModalEdit: (): null => null,
};

export const useRatingModal = (): {
  state: RatingModalStateType;
  setter: RatingModalSetterType;
} => {
  const [ratingModalOpen, setRatingModalOpen] =
    React.useState<RatingModalOpenContext | null>(null);
  const [ratingModalEdit, setRatingModalEdit] =
    React.useState<RatingModalEditContext | null>(null);

  return {
    state: {
      ratingModalOpen,
      ratingModalEdit,
    },
    setter: {
      setRatingModalOpen,
      setRatingModalEdit,
    },
  };
};
