import useSWR from 'swr';

import {
  getCouponDetail,
  getCouponDetailProps,
  getCouponDetailReturn,
} from 'api';

const useCouponDetail = (
  args: getCouponDetailProps & { initialData?: getCouponDetailReturn },
): {
  isLoading: boolean;
  isError: boolean;
  data?: getCouponDetailReturn;
} => {
  const { initialData, ...getCouponDetailArgs } = args;

  const { data, error } = useSWR(
    {
      ...{ api: 'getCouponDetail' },
      ...getCouponDetailArgs,
    },
    getCouponDetail,
    {
      fallbackData: initialData,
    },
  );

  return {
    isLoading: !error && !data,
    isError: error,
    data,
  };
};
export default useCouponDetail;
