import * as React from 'react';

export type CouponModalStateType = {
  openCouponModalDialog: boolean;
};

export type CouponModalSetterType = {
  setOpenCouponModalDialog: React.Dispatch<React.SetStateAction<boolean>>;
};

export const defaultCouponModalSetter = {
  setOpenCouponModalDialog: (): null => null,
};

export const useCouponModal = (): {
  state: CouponModalStateType;
  setter: CouponModalSetterType;
} => {
  const [openCouponModalDialog, setOpenCouponModalDialog] =
    React.useState(false);

  return {
    state: {
      openCouponModalDialog,
    },
    setter: {
      setOpenCouponModalDialog,
    },
  };
};
