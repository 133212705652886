import * as React from 'react';

export type UserDrawerStateType = {
  userNavMenuOpen: boolean;
};

export type UserDrawerSetterType = {
  setUserNavMenuOpen: React.Dispatch<React.SetStateAction<boolean>>;
};

export const defaultUserDrawerSetter = {
  setUserNavMenuOpen: (): null => null,
};

export const useUserDrawer = (): {
  state: UserDrawerStateType;
  setter: UserDrawerSetterType;
} => {
  const [userNavMenuOpen, setUserNavMenuOpen] = React.useState(false);

  return {
    state: {
      userNavMenuOpen,
    },
    setter: {
      setUserNavMenuOpen,
    },
  };
};
