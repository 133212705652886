import * as React from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { Box, Typography } from '@mui/material';

import { VerificationEmail } from 'assets/Icon';
import { TextField, FieldBox, StyledButton } from 'components/shared';
import { GlobalModalSetter } from 'components/modal';
import { validateUser } from 'api';
import { useUserInfo, useGetCart } from 'composables';
import SignupContext from '../SignupContext';

const SignupVerification = (): JSX.Element => {
  const { email, hash } = React.useContext(SignupContext);
  const { userModalDispatch } = React.useContext(GlobalModalSetter);
  const { mutate: mutateUserInfo, data: userInfo } = useUserInfo({});
  const { mutate: mutateCart } = useGetCart({});

  React.useEffect(() => {
    if (userInfo?.email) {
      userModalDispatch({ type: 'close' });
    }
  }, [userInfo, userModalDispatch]);

  const formik = useFormik({
    initialValues: {
      token: '',
    },
    validationSchema: Yup.object({
      token: Yup.string()
        .test(
          'check-token-format',
          'Token should be 6 digit number',
          (value: string | undefined) => {
            if (typeof value === 'undefined') {
              return true;
            }

            return Boolean(value.match(/^\d{6}$/g));
          },
        )
        .required('Required'),
    }),
    onSubmit: async (v, { setSubmitting, setErrors }) => {
      setSubmitting(true);

      const r = await validateUser({
        token: v.token,
        hash,
      });

      mutateUserInfo();
      mutateCart();

      userModalDispatch({ type: 'close' });

      setSubmitting(false);
    },
  });

  return (
    <>
      <FieldBox>
        <Box display="flex" justifyContent="center">
          <Box
            width="8rem"
            height="8rem"
            paddingBottom={4}
            color="primary.main"
          >
            <VerificationEmail width="100%" height="100%" />
          </Box>
        </Box>
        <Typography
          variant="body1"
          sx={{
            fontWeight: 'fontWeightLight',
            color: 'secondary.main',
            paddingBottom: 3,
          }}
        >
          A Verification email has been sent to this email address{' '}
          <Box component="span" sx={{ color: 'primary.main' }}>
            {email}
          </Box>
          . Please click the link in the email to verify your account or input 6
          digits code below.
        </Typography>
      </FieldBox>
      <form onSubmit={formik.handleSubmit}>
        <TextField
          name="token"
          label="Verification Code"
          placeholder="6 Digit Verification Code"
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          value={formik.values.token}
          error={formik.touched.token && Boolean(formik.errors.token)}
          helperText={
            formik.touched.token && formik.errors.token !== '-'
              ? formik.errors.token
              : undefined
          }
          disabled={formik.isSubmitting}
        />
        <StyledButton
          variant="UserForm"
          type="submit"
          sx={{ mt: 1 }}
          disabled={formik.isSubmitting}
        >
          Verified
        </StyledButton>
      </form>
    </>
  );
};
export default SignupVerification;
