import * as React from 'react';
import { Breadcrumbs, Link } from '@mui/material';

import {
  Facebook as FacebookIconTrans,
  FacebookBW as FacebookIconTransBW,
  Ig as InstagramIconTrans,
  IgBW as InstagramIconTransBW,
  Line as LineIconTrans,
  LineBW as LineIconTransBW,
  Pinterest as PinterestIconTrans,
  PinterestBW as PinterestIconTransBW,
  Twitter as TwitterIconTrans,
  TwitterBW as TwitterIconTransBW,
  Youtube as YoutubeIconTrans,
  YoutubeBW as YoutubeIconTransBW,
} from 'assets/SocialMedia';

type BreadcrumbsItemProps = {
  icon: React.ReactNode;
  iconBW: React.ReactNode;
  title: string;
  href: string;
};
const BreadcrumbsItem = (props: BreadcrumbsItemProps): JSX.Element => {
  const { icon, iconBW, title, href } = props;
  const [isHover, setIsHover] = React.useState(false);

  return (
    <Link
      onMouseEnter={() => setIsHover(true)}
      onMouseLeave={() => setIsHover(false)}
      color="inherit"
      href={href}
      underline="none"
      target="_blank"
      rel="noopener"
      title={`${title} - cafn.co`}
      sx={{ display: 'flex' }}
    >
      {isHover ? icon : iconBW}
    </Link>
  );
};

const SocialLink = (): JSX.Element => {
  return (
    <Breadcrumbs separator=" " sx={{ color: 'common.darkGrey' }}>
      <BreadcrumbsItem
        title="Facebook"
        icon={<FacebookIconTrans />}
        iconBW={<FacebookIconTransBW />}
        href="https://facebook.com/cafnco"
      />
      <BreadcrumbsItem
        title="Instagram"
        icon={<InstagramIconTrans />}
        iconBW={<InstagramIconTransBW />}
        href="https://www.instagram.com/cafnco"
      />
      <BreadcrumbsItem
        title="Line"
        icon={<LineIconTrans />}
        iconBW={<LineIconTransBW />}
        href="https://lin.ee/cUktOSM"
      />
      <BreadcrumbsItem
        title="Twitter"
        icon={<TwitterIconTrans />}
        iconBW={<TwitterIconTransBW />}
        href="https://twitter.com/cafnco"
      />
      <BreadcrumbsItem
        title="Youtube"
        icon={<YoutubeIconTrans />}
        iconBW={<YoutubeIconTransBW />}
        href="https://www.youtube.com/c/cafnco"
      />
      <BreadcrumbsItem
        title="Pinterest"
        icon={<PinterestIconTrans />}
        iconBW={<PinterestIconTransBW />}
        href="https://www.pinterest.com/cafn_co"
      />
    </Breadcrumbs>
  );
};
export default SocialLink;
