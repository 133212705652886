import * as React from 'react';
import { Box, Container, Grid, ButtonBase } from '@mui/material';

import SocialLink from 'components/shared/SocialLink';
import { LogoCaffeinnne } from 'assets/Branding';
import Copyright from '../Copyright';
import Terms from '../Terms';
import Tagline from '../Tagline';
import { MainFooterNav, CoffeeBeansNav, RoasterFooterNav } from '../FooterList';

const Footer = (): JSX.Element => {
  const footerNavMain = [
    { title: 'About us', href: '/about' },
    { title: 'Contact us', href: '/contact' },
    // { title: 'FAQs', href: '#' },
  ];

  const footerTerms = [
    { title: 'Privacy notice', href: '/privacy-notice' },
    { title: 'Cookie policy', href: '/cookie-policy' },
    // { title: 'Terms of use', href: '/about' },
    // { title: 'Terms of purchase', href: '/about' },
  ];

  return (
    <Container
      maxWidth={false}
      component="footer"
      disableGutters
      sx={{ marginTop: '75px', color: 'common.darkGrey', zIndex: 2 }}
    >
      <Container
        maxWidth="xl"
        disableGutters
        sx={{ padding: (theme) => theme.spacing(0, 3, 2, 3) }}
      >
        <Tagline />
      </Container>
      <Container
        id="bottom-footer-container"
        maxWidth={false}
        disableGutters
        sx={{ backgroundColor: 'common.lightGrey' }}
      >
        <Container
          maxWidth="xl"
          disableGutters
          sx={{ padding: (theme) => theme.spacing(2, 3) }}
        >
          <Box sx={{ marginTop: '18px', width: '4.5em' }}>
            <LogoCaffeinnne width="100%" height="100%" />
          </Box>
          <Grid container>
            <Grid item xs={12} md={3}>
              <MainFooterNav mainNav={footerNavMain} />
            </Grid>
            <Grid item xs={12} md={3}>
              <CoffeeBeansNav />
            </Grid>
            {/* <Grid item xs={12} md={3}>
              <RoasterFooterNav />
            </Grid> */}
          </Grid>
          <Box sx={{ marginTop: '10px' }}>
            <SocialLink />
          </Box>
          <Grid
            container
            sx={{
              marginTop: {
                xs: 15,
                md: '2px',
              },
              alignItems: 'flex-end',
              // set to 50 to account for 2 line announcement
              marginBottom: '50px', // 24px [Text] + (8px * 2) [Margin]
            }}
          >
            <Grid
              item
              xs={12}
              md={6}
              sx={{ pt: { md: 2 } }}
              order={{
                xs: 2,
                md: 1,
              }}
            >
              <Copyright />
            </Grid>
            <Grid
              item
              xs={12}
              md={4}
              sx={{ pt: { md: 2 } }}
              order={{
                xs: 3,
                md: 2,
              }}
            >
              <Terms footerTerms={footerTerms} />
            </Grid>
            <Grid
              item
              xs={12}
              md={2}
              order={{
                xs: 1,
                md: 3,
              }}
              sx={{
                pt: { md: 2 },
                display: 'flex',
                justifyContent: {
                  xs: 'flex-start',
                  md: 'flex-end',
                },
                mb: {
                  xs: 1,
                  md: 'unset',
                },
              }}
            >
              <ButtonBase
                target="_blank"
                href="https://www.trustmarkthai.com/callbackData/popup.php?data=d37a-20-6-9d5a5fd866afad5c10237514311c139aec905a4a95"
              >
                {/* eslint-disable-next-line @next/next/no-img-element */}
                <img
                  alt="dbd_registered"
                  width="80px"
                  src="/image/dbd_registered.png"
                />
              </ButtonBase>
            </Grid>
          </Grid>
        </Container>
      </Container>
    </Container>
  );
};
export default Footer;
