import { Caffe, CaffEmptyPromise } from 'apiclient';
import { AxiosRequestConfig } from 'axios';
import { CoffeeGrind } from 'types/product';

const caffe = new Caffe();

export interface addItemProps {
  item: string;
  grindLevel: CoffeeGrind;
  qty?: number;
  axiosConfig?: AxiosRequestConfig;
}

export type addItemReturn = CaffEmptyPromise;

const addItem = async (props: addItemProps): CaffEmptyPromise => {
  // will use cart_id from cookies and session user
  const { axiosConfig, ...data } = props;
  return caffe.request({
    ...{
      method: 'POST',
      url: '/api/method/caff.e.cart.add_item',
      data: {
        item: data.item,
        grind_level: data.grindLevel,
        qty: data.qty,
      },
      withCredentials: true,
    },
    ...axiosConfig,
  });
};
export default addItem;
