import * as React from 'react';
import Button, { ButtonProps } from '../Button';
import FieldBox from '../FieldBox';

export type StyledButtonProps = {
  variant: 'UserForm' | 'CheckoutText';
} & Omit<ButtonProps, 'variant'>;
const StyledButton = (props: StyledButtonProps): JSX.Element => {
  const { variant, ...propsButton } = props;

  if (variant === 'UserForm') {
    return (
      <FieldBox>
        <Button
          {...propsButton}
          fontFamily="sansSerif"
          fontSize="subtitle2"
          variant="contained"
          fullWidth
          disableElevation
          sx={{
            ...{ pt: 0.25, pb: 0.25 },
            ...props?.sx,
          }}
        />
      </FieldBox>
    );
  }

  if (variant === 'CheckoutText') {
    return (
      <Button
        {...propsButton}
        color="success"
        fontSize="body1"
        size="small"
        sx={{ ...{ fontWeight: 'fontWeightLight' }, ...props?.sx }}
      />
    );
  }

  return <Button {...propsButton} />;
};
export default StyledButton;
