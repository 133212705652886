import * as React from 'react';
import { Box, ButtonBase, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import { Add as AddIcon, Link as LinkIcon } from '@mui/icons-material';
import { useFormik, FormikValues } from 'formik';
import * as Yup from 'yup';
import { useRouter } from 'next/router';
import posthog from 'posthog-js';

import { lineHandleConfirm } from 'api';
import { useGetCart, useUserInfo } from 'composables';
import { GlobalModalSetter } from 'components/modal';
import { NegativeLine as NegativeLineIcon } from 'assets/Icon';
import { TextField, StyledButton, PasswordField, FieldBox } from 'components/shared';
import UserModalHeader from '../UserModalHeader';

const ActionSelectorBtn = styled(ButtonBase)({
  display: 'flex',
  flexDirection: 'column',
  flexGrow: 1,
  minHeight: '100px',
  backgroundColor: '#435244',
  color: 'white',
  borderRadius: '5px',
  padding: '16px',
});

type modalAction = 'createNew' | 'linkUser' | 'error' | undefined;

type ActionSelectorProps = {
  setAction: React.Dispatch<React.SetStateAction<modalAction>>;
  hash?: string;
};
const ActionSelector = (props: ActionSelectorProps): JSX.Element => {
  const { setAction, hash } = props;
  const router = useRouter();

  const { mutate: mutateUserInfo } = useUserInfo({});
  const { mutate: mutateCart } = useGetCart({});
  const { userModalDispatch, setSurpressCookiesConsentDialog } =
    React.useContext(GlobalModalSetter);

  const handleCreateNew = async () => {
    try {
      const { redirectUrl, user } = await lineHandleConfirm({
        action: 'createNew',
        hash,
      });

      userModalDispatch({ type: 'close' });
      mutateCart();
      mutateUserInfo();
      if (typeof user === 'string') {
        posthog.identify(user);
      }
      setSurpressCookiesConsentDialog(false);
      router.push(redirectUrl || '/');
    } catch {
      setAction('error');
    }
  };

  return (
    <Box
      display="flex"
      sx={{
        columnGap: '20px',
        paddingTop: '24px',
      }}
    >
      <ActionSelectorBtn onClick={handleCreateNew}>
        <AddIcon sx={{ fontSize: '48px', margin: '12px' }} />
        <Typography>Create new user</Typography>
      </ActionSelectorBtn>
      <ActionSelectorBtn
        onClick={() => {
          setAction('linkUser');
        }}
      >
        <LinkIcon sx={{ fontSize: '48px', margin: '12px' }} />
        <Typography>Link with email user</Typography>
      </ActionSelectorBtn>
    </Box>
  );
};

type LinkUserFormProps = {
  hash?: string;
  setAction: React.Dispatch<React.SetStateAction<modalAction>>;
};
const LinkUserForm = (props: LinkUserFormProps): JSX.Element => {
  const { hash, setAction } = props;
  const router = useRouter();

  const { mutate: mutateUserInfo } = useUserInfo({});
  const { mutate: mutateCart } = useGetCart({});
  const { userModalDispatch, setSurpressCookiesConsentDialog } =
    React.useContext(GlobalModalSetter);

  type LinkUserFormikValues = {
    formError?: string;
  } & FormikValues;

  const formik = useFormik<LinkUserFormikValues>({
    initialValues: {
      email: '',
      password: '',
    },
    validationSchema: Yup.object({
      email: Yup.string().email('Invalid email address').required('Required'),
      password: Yup.string().required('No password provided'),
    }),

    onSubmit: async (v, { setSubmitting, setErrors }) => {
      setSubmitting(true);

      try {
        const { redirectUrl } = await lineHandleConfirm({
          action: 'linkUser',
          hash,
          usr: v.email,
          pwd: v.password,
        });

        userModalDispatch({ type: 'close' });
        mutateCart();
        mutateUserInfo();
        posthog.identify(v.email);
        setSurpressCookiesConsentDialog(false);
        router.push(redirectUrl || '/');
      } catch (err: any) {
        if (
          err &&
          typeof err === 'object' &&
          'message' in err &&
          typeof err.message === 'string' &&
          err.message.match(/401/)
        ) {
          setSubmitting(false);
          setErrors({
            formError: 'Incorrect username or password',
            email: '-',
            password: '-',
          });
        } else {
          setAction('error');
        }
      }

      setSubmitting(false);
    },
  });
  return (
    <form onSubmit={formik.handleSubmit}>
      <TextField
        name="email"
        label="Email Address"
        placeholder="Please enter your email address"
        onChange={formik.handleChange}
        onBlur={formik.handleBlur}
        value={formik.values.email}
        error={formik.touched.email && Boolean(formik.errors.email)}
        helperText={formik.touched.email && formik.errors.email !== '-' && formik.errors.email}
        disabled={formik.isSubmitting}
      />
      <PasswordField
        name="password"
        label="Password"
        placeholder="Please enter your password"
        onChange={formik.handleChange}
        onBlur={formik.handleBlur}
        value={formik.values.password}
        error={formik.touched.password && Boolean(formik.errors.password)}
        helperText={
          formik.touched.password && formik.errors.password !== '-' && formik.errors.password
        }
        disabled={formik.isSubmitting}
      />
      {formik.errors.formError ? (
        <FieldBox
          sx={{
            'paddingLeft': 1,
            '& p': {
              color: 'error.main',
              fontWeight: 'fontWeightLight',
            },
          }}
        >
          <Typography variant="body2">{formik.errors.formError}</Typography>
        </FieldBox>
      ) : null}
      <StyledButton
        variant="UserForm"
        type="submit"
        disabled={formik.isSubmitting}
        sx={{
          mt: 3,
        }}
      >
        Link with email user
      </StyledButton>
    </form>
  );
};

const ErrorFeedback = (): JSX.Element => {
  const router = useRouter();
  const { mutate: mutateUserInfo } = useUserInfo({});
  const { mutate: mutateCart } = useGetCart({});
  const { userModalDispatch, setSurpressCookiesConsentDialog } =
    React.useContext(GlobalModalSetter);

  const goBackOnClick = () => {
    userModalDispatch({ type: 'close' });
    mutateCart();
    mutateUserInfo();
    setSurpressCookiesConsentDialog(false);
    router.push('/');
  };

  return (
    <Box
      display="flex"
      sx={{
        justifyContent: 'center',
        flexDirection: 'column',
        alignItems: 'stretch',
      }}
    >
      <Box sx={{ display: 'flex', justifyContent: 'center', mt: 5 }}>
        <NegativeLineIcon width="64px" height="64px" />
      </Box>
      <Typography textAlign="center" sx={{ mt: 2, mb: 5 }}>
        An unexpected error occurred.
        <br />
        To continue, please go back to the home page and try again.
      </Typography>
      <StyledButton
        variant="UserForm"
        type="submit"
        sx={{
          mt: 3,
        }}
        onClick={goBackOnClick}
      >
        Go back to homepage
      </StyledButton>
    </Box>
  );
};

export type NewLineUserModalProps = {
  defaultAction?: modalAction;
};
const NewLineUserModal = (props: NewLineUserModalProps): JSX.Element => {
  const { defaultAction } = props;

  const router = useRouter();
  const {
    query: { hash },
  } = router;

  const [action, setAction] = React.useState<modalAction>(defaultAction);

  return (
    <>
      <UserModalHeader title={action === undefined ? 'New Line User' : 'Link with email user'} />
      <Box sx={{ display: action === undefined ? 'block' : 'none' }}>
        <ActionSelector setAction={setAction} hash={hash as string} />
      </Box>
      <Box sx={{ display: action === 'linkUser' ? 'block' : 'none' }}>
        <LinkUserForm setAction={setAction} hash={hash as string} />
      </Box>
      <Box sx={{ display: action === 'error' ? 'block' : 'none' }}>
        <ErrorFeedback />
      </Box>
    </>
  );
};
export default NewLineUserModal;
