import * as React from 'react';
import { useFormik, FormikValues } from 'formik';
import * as Yup from 'yup';
import { Typography } from '@mui/material';

import { TextField, FieldBox, StyledButton } from 'components/shared';
import { forgotPassword as forgotPasswordApi } from 'api/user';

export type ForgotPassFormDisplayProps = {
  setDisplayContext: () => void;
  setEmailContext: (email: string) => void;
};
const ForgotPassFormDisplay = (
  props: ForgotPassFormDisplayProps,
): JSX.Element => {
  type ForgotPassFormikValues = {
    formError?: string;
  } & FormikValues;

  const { setDisplayContext, setEmailContext } = props;

  const formik = useFormik<ForgotPassFormikValues>({
    initialValues: {
      email: '',
    },
    validationSchema: Yup.object({
      email: Yup.string().email('Invalid email address').required('Required'),
    }),

    onSubmit: async (v, { setSubmitting, setErrors }) => {
      setSubmitting(true);

      try {
        await forgotPasswordApi({
          email: v.email,
        });
        setDisplayContext();
        setEmailContext(v.email);
      } catch (err: any) {
        setSubmitting(false);
        if (typeof err === 'object' && err !== null && 'message' in err) {
          if (err.message.includes('Email not found')) {
            setErrors({
              email: 'Email not found - Please check email address',
            });
            return;
          }
        }
        setErrors({
          formError: 'Something went wrong',
        });
      }
    },
  });

  return (
    <form onSubmit={formik.handleSubmit}>
      <FieldBox>
        <Typography
          variant="body1"
          sx={{
            fontWeight: 'fontWeightLight',
            color: 'secondary.main',
            paddingBottom: 3,
          }}
        >
          Please enter the email account to reset the password.
        </Typography>
      </FieldBox>
      <TextField
        name="email"
        label="Email Address"
        placeholder="Please enter your email address"
        onChange={formik.handleChange}
        onBlur={formik.handleBlur}
        value={formik.values.email}
        error={formik.touched.email && Boolean(formik.errors.email)}
        helperText={
          formik.touched.email && formik.errors.email !== '-'
            ? formik.errors.email
            : undefined
        }
        disabled={formik.isSubmitting}
      />
      {formik.errors.formError ? (
        <FieldBox
          sx={{
            'paddingLeft': 1,
            '& p': {
              color: 'error.main',
              fontWeight: 'fontWeightLight',
            },
          }}
        >
          <Typography variant="body2">{formik.errors.formError}</Typography>
        </FieldBox>
      ) : null}
      <StyledButton
        variant="UserForm"
        type="submit"
        disabled={formik.isSubmitting}
        sx={{ mt: 3 }}
      >
        Next
      </StyledButton>
    </form>
  );
};
export default ForgotPassFormDisplay;
