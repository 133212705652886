import * as React from 'react';

export type ResetPassContextType = {
  display: string;
  setDisplay: React.Dispatch<React.SetStateAction<string>>;

  email: string;
  setEmail: React.Dispatch<React.SetStateAction<string>>;
};

const ResetPassContext = React.createContext<ResetPassContextType>({
  display: 'Form',
  setDisplay: () => null,

  email: '',
  setEmail: () => null,
});
export default ResetPassContext;
