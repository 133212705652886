type getImgUrlArgs =
  | {
      imgPath?: string;
      encodeUri?: boolean;
      transformation?: string;
    }
  | string;
export const getImgUrl = (args: getImgUrlArgs): string => {
  // e = effective
  let eImgPath: string | undefined;
  let eEncodeUri: boolean | undefined;
  let eTransformation: string | undefined;

  if (typeof args === 'string') {
    eImgPath = args;
  } else {
    const { imgPath, encodeUri = false, transformation } = args;
    eImgPath = imgPath;
    eEncodeUri = encodeUri;
    eTransformation = transformation;
  }

  if (!eImgPath) {
    return '';
  }

  if (eImgPath.match(/https?:\/\//)) {
    return eImgPath;
  }

  let tranParam = '';

  if (eTransformation) {
    tranParam = `?tr=${eTransformation}`;
  }

  let url = `${process.env.NEXT_PUBLIC_IMG_CDN_ENDPOINT}/${eImgPath}${tranParam}`;
  if (eEncodeUri) {
    url = encodeURI(url);
  }

  return url;
};

export const getBackEndHostname = (url?: string): string | undefined => {
  let backEndUrl = url;
  if (!url) {
    backEndUrl = process.env.NEXT_PUBLIC_BACKEND_URL;
  }

  // eslint-disable-next-line no-useless-escape
  const backEndRegex = backEndUrl?.match(/https?:\/\/([\.\w\d-_:]+)/);
  if (backEndRegex?.length === 2) {
    return backEndRegex[1];
  }
  return backEndUrl;
};

export const getImgUrlReplaceBe = (args: getImgUrlArgs): string => {
  // e = effective
  let eImgPath: string | undefined;
  let eEncodeUri: boolean | undefined;
  let eTransformation: string | undefined;

  if (typeof args === 'string') {
    eImgPath = args;
  } else {
    const { imgPath, encodeUri = false, transformation } = args;
    eImgPath = imgPath;
    eEncodeUri = encodeUri;
    eTransformation = transformation;
  }

  const backEndHostname = getBackEndHostname();

  const frappeImg = eImgPath?.match(
    // eslint-disable-next-line no-useless-escape
    new RegExp(`https?:/\/${backEndHostname}\/files\/(.*)`),
  );

  if (frappeImg) {
    // eslint-disable-next-line prefer-destructuring
    eImgPath = frappeImg[1];
  }

  return getImgUrl({
    imgPath: eImgPath,
    encodeUri: eEncodeUri,
    transformation: eTransformation,
  });
};

type breakpoint = 'xs' | 'sm' | 'md' | 'lg';
export const getImgUrlByBreakpoint = (
  imgPath: string,
  breakPoint?: breakpoint,
): string => {
  const breakPointImgWidth = {
    xs: 600,
    sm: 960,
    md: 1280,
    lg: 1920,
  };

  const transformationList = [];

  let width = breakPointImgWidth.lg;

  if (breakPoint) {
    if (breakPoint in breakPointImgWidth) {
      width = breakPointImgWidth[breakPoint];
    }
  }

  transformationList.push(`w-${width}`);

  let transformation = '';

  if (transformationList) {
    transformation = transformationList.join(',');
  }

  return getImgUrlReplaceBe({
    imgPath,
    encodeUri: true,
    transformation,
  });
};

export const getImgHtml = (html?: string): string[] => {
  let imgHtml: string[] = [];

  if (!html) {
    return [];
  }

  const matchImgHtml = html.match(/<img .*?>/gi);
  if (matchImgHtml) {
    imgHtml = matchImgHtml;
  }

  return imgHtml;
};

export const stripErpFiles = (src: string): string => {
  let filename: string | undefined;

  const splitSrcList = src.split('/');
  if (splitSrcList.length === 2 && splitSrcList[0] === 'files') {
    filename = splitSrcList.pop();
  }
  if (filename) {
    return filename;
  }

  return src;
};

export const getImgSrc = (imgHtml: string): string => {
  const imgSrc = imgHtml.match(/src=['"](.*?)['"]/);
  let src = '';
  if (imgSrc) {
    // eslint-disable-next-line prefer-destructuring
    src = imgSrc[1];
  }
  return src;
};

export const getImgAttr = (imgHtml: string): { [key: string]: string } => {
  const imgAttr = imgHtml.matchAll(/ ([\w]+)=['"](.*?)["']/gi);
  const attrDict: { [key: string]: string } = {};

  Array.from(imgAttr).forEach((attr) => {
    let key = attr[1] ? attr[1] : '';
    const value = attr[2] ? attr[2] : '';
    if (key) {
      if (key === 'class') {
        key = 'className';
      }
      attrDict[key] = value;
    }
  });

  return attrDict;
};

export const addImgsetToImg = (imgHtml: string): string => {
  const imgAttr = getImgAttr(imgHtml);

  let { src } = imgAttr;
  const { alt, className } = imgAttr;

  if (!src.match(/https?:\/\//)) {
    src = stripErpFiles(src);

    const srcset = `
        ${getImgUrl({
          imgPath: src,
          encodeUri: false,
          transformation: 'w-600',
        })} 600w,
        ${getImgUrl({
          imgPath: src,
          encodeUri: false,
          transformation: 'w-960',
        })} 960w,
        ${getImgUrl({
          imgPath: src,
          encodeUri: false,
          transformation: 'w-1280',
        })} 1280w,
        ${getImgUrl({
          imgPath: src,
          encodeUri: false,
          transformation: 'w-1920',
        })} 1920w`;
    const size = `
        (max-width: 1280px) 100vw, 1920px`;

    return `
      <img
        src="${getImgUrl({
          imgPath: src,
          encodeUri: false,
        })}"
        srcset="${srcset}"
        size="${size}"
        ${alt ? `alt="${alt}"` : ''}
        ${className ? `class="${className}"` : ''}
      />`;
  }

  return imgHtml;
};

export const convertHtmlImgToCdnImg = (html: string): string => {
  const imgTagList = getImgHtml(html);
  let convertedHtml = html;
  imgTagList.forEach((imgTag) => {
    convertedHtml = convertedHtml.replace(imgTag, addImgsetToImg(imgTag));
  });
  return convertedHtml;
};
