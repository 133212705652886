import { Caffe, CaffePromise } from 'apiclient';

const caffe = new Caffe();

export interface lineAuthProps {
  redirectUrl: string;
  forceCreate?: boolean;
}

export interface lineAuthReturn {
  email: string;
  displayName: string;
  avatar: string;
}

const lineAuth = async (props: lineAuthProps): CaffePromise<lineAuthReturn> => {
  const { redirectUrl, forceCreate } = props;

  return caffe.request({
    method: 'GET',
    url: '/api/method/caff.e.auth.line.get_auth_url',
    params: {
      redirect_url: redirectUrl,
      force_create: forceCreate,
    },
    withCredentials: true,
  });
};
export default lineAuth;
