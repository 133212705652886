import * as React from 'react';
import { Box } from '@mui/material';

import UserModalHeader from '../UserModalHeader';
import ForgotPassFeedback from '../ForgotPassFeedback';
import ForgotPassForm from '../ForgotPassForm';
import ForgotPassContext from '../ForgotPassContext';

const ForgotPassModal = (): JSX.Element => {
  const [display, setDisplay] = React.useState('Form');
  const [email, setEmail] = React.useState('');

  return (
    <ForgotPassContext.Provider
      value={{ display, setDisplay, email, setEmail }}
    >
      <UserModalHeader
        title="Reset Password"
        back="Login"
        backTitle="Go Back"
      />
      <Box sx={{ display: display === 'Form' ? 'block' : 'none' }}>
        <ForgotPassForm />
      </Box>
      <Box sx={{ display: display === 'Feedback' ? 'block' : 'none' }}>
        <ForgotPassFeedback />
      </Box>
    </ForgotPassContext.Provider>
  );
};
export default ForgotPassModal;
