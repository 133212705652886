const transparentBase = new Set([
  '/404',
  '/error',
  '/about',
  '/roaster',
  '/contact',
  '/user/login',
  '/user/new-line-user',
  '/user/change-password',
  '/user/reset-password',
  '/user/update-password',
  '/user/verification',
]);

export const transparentRoutes = new Set(['/', ...transparentBase]);

export const transparentTagline = new Set([...transparentBase]);
