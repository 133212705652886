import * as React from 'react';

import CartDrawerDyna, {
  CartDrawerSetterType,
  useCartDrawer,
  defaultCartDrawerSetter,
} from './CartDrawer';
import CookiesConsent, {
  CookiesConsentSetterType,
  useCookiesConsent,
  defaultCookiesConsentSetter,
} from './CookiesConsent';
import CouponModal, {
  CouponModalSetterType,
  useCouponModal,
  defaultCouponModalSetter,
} from './CouponModal';
import FeedbackModalDyna, {
  FeedbackModalSetterType,
  useFeedbackModal,
  defaultFeedbackModalSetter,
} from './FeedbackModal';
import RatingModalDyna, {
  RatingModalSetterType,
  useRatingModal,
  defaultRatingModalSetter,
} from './RatingModal';
import UserDrawer, {
  UserDrawerSetterType,
  useUserDrawer,
  defaultUserDrawerSetter,
} from './UserDrawer';
import UserModal, {
  UserModalSetterType,
  useUserModal,
  defaultUserModalSetter,
} from './UserModal';

type GlobalModalSetterType = CartDrawerSetterType &
  CookiesConsentSetterType &
  CouponModalSetterType &
  FeedbackModalSetterType &
  RatingModalSetterType &
  UserDrawerSetterType &
  UserModalSetterType;
export const GlobalModalSetter = React.createContext<GlobalModalSetterType>({
  ...defaultCartDrawerSetter,
  ...defaultCookiesConsentSetter,
  ...defaultCouponModalSetter,
  ...defaultFeedbackModalSetter,
  ...defaultRatingModalSetter,
  ...defaultUserDrawerSetter,
  ...defaultUserModalSetter,
});

type GlobalModalProviderProps = {
  children: React.ReactNode;
};
const GlobalModalProvider = (props: GlobalModalProviderProps): JSX.Element => {
  const { children } = props;

  const { state: cartDrawerState, setter: cartDrawerSetter } = useCartDrawer();
  const { state: cookiesConsentState, setter: cookiesConsentSetter } =
    useCookiesConsent();
  const { state: couponModalState, setter: couponModalSetter } =
    useCouponModal();
  const { state: feedbackModalState, setter: feedbackModalSetter } =
    useFeedbackModal();
  const { state: ratingModalState, setter: ratingModalSetter } =
    useRatingModal();
  const { state: userDrawerState, setter: userDrawerSetter } = useUserDrawer();
  const { state: userModalState, setter: userModalSetter } = useUserModal();

  return (
    <GlobalModalSetter.Provider
      value={{
        ...cartDrawerSetter,
        ...cookiesConsentSetter,
        ...couponModalSetter,
        ...feedbackModalSetter,
        ...ratingModalSetter,
        ...userDrawerSetter,
        ...userModalSetter,
      }}
    >
      <CartDrawerDyna {...cartDrawerState} {...cartDrawerSetter} />
      <CouponModal
        {...couponModalState}
        {...couponModalSetter}
        {...cookiesConsentState}
      />
      <FeedbackModalDyna {...feedbackModalState} {...feedbackModalSetter} />
      <RatingModalDyna {...ratingModalState} {...ratingModalSetter} />
      <UserDrawer {...userDrawerState} {...userDrawerSetter} />
      <UserModal {...userModalState} {...userModalSetter} />
      <CookiesConsent {...cookiesConsentState} {...cookiesConsentSetter} />
      {children}
    </GlobalModalSetter.Provider>
  );
};
export default GlobalModalProvider;
