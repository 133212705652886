import { Caffe, CaffePromise } from 'apiclient';

const caffe = new Caffe();

export interface loginProps {
  usr: string;
  pwd: string;
}

export interface loginReturn {
  email: string;
  displayName: string;
  avatar: string;
}

const login = async (props: loginProps): CaffePromise<loginReturn> => {
  return caffe.request({
    method: 'POST',
    url: '/api/method/caff.e.auth.email.login',
    data: props,
    withCredentials: true,
  });
};
export default login;
