import * as React from 'react';

import {
  userModalReducer,
  userModalStateType,
  userModalActionType,
} from './UserModalReducer';

export type UserModalStateType = {
  userModalState: userModalStateType;
};

export type UserModalSetterType = {
  userModalDispatch: React.Dispatch<userModalActionType>;
};

export const defaultUserModalSetter = {
  userModalDispatch: (): null => null,
};

export const useUserModal = (): {
  state: UserModalStateType;
  setter: UserModalSetterType;
} => {
  const [userModalState, userModalDispatch] = React.useReducer(
    userModalReducer,
    { type: 'None' },
  );

  return {
    state: {
      userModalState,
    },
    setter: {
      userModalDispatch,
    },
  };
};
