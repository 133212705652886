import * as React from 'react';

export type SignupContextType = {
  display: string;
  setDisplay: React.Dispatch<React.SetStateAction<string>>;

  hash: string;
  setHash: React.Dispatch<React.SetStateAction<string>>;

  email: string;
  setEmail: React.Dispatch<React.SetStateAction<string>>;
};
const SignupContext = React.createContext<SignupContextType>({
  display: 'Form',
  setDisplay: () => null,

  hash: '',
  setHash: () => null,

  email: '',
  setEmail: () => null,
});
export default SignupContext;
