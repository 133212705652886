import * as React from 'react';
import Link from 'next/link';
import { Box, ButtonBase, IconButton, Toolbar } from '@mui/material';
import { useRouter } from 'next/router';
import { SxProps } from '@mui/system';

import { navBtnType } from 'types/navBtn';
import { transparentRoutes } from 'styles/route-layout';
import { Search as SearchIcon } from 'assets/Icon';
import { LogoCaffeinnne } from 'assets/Branding';
import UserButton from '../UserButton';
import CartButton from '../CartButton';

type NavMenuProps = {
  sections: navBtnType[];
};
const NavMenu = (props: NavMenuProps): JSX.Element => {
  const { sections } = props;

  const aSx: SxProps = {
    'fontFamily': 'fontFamilySansSerif',
    'textDecoration': 'none',
    'fontSize': '1.2rem',
    'flexShrink': 0,
    'textTransform': 'initial',
    'pl': 2,
    'pr': 2,
    'color': 'inherit',
    '&:hover': {
      color: 'inherit',
    },
  };

  return (
    <>
      {sections.map((section) => {
        if (section?.href) {
          return (
            <Link key={section.title} href={section.href} passHref prefetch={false}>
              <Box component="a" sx={aSx} title={section.title} href={section.href}>
                {section.title}
              </Box>
            </Link>
          );
        }
        return (
          <Box
            key={section.title}
            component="a"
            sx={{ ...aSx, ...{ cursor: 'pointer' } }}
            title={section.title}
            onClick={section.onClick}
          >
            {section.title}
          </Box>
        );
      })}
    </>
  );
};

export type WebHeaderProps = {
  sections: navBtnType[];
};
const WebHeader = (props: WebHeaderProps): JSX.Element => {
  const { sections } = props;
  const router = useRouter();

  const toolbarSx = {
    minHeight: '50px',
    color: transparentRoutes.has(router.route) ? 'common.white' : 'secondary.main',
    backgroundColor: transparentRoutes.has(router.route) ? 'transparent' : 'common.white',
  };

  return (
    <Toolbar sx={toolbarSx}>
      <Box sx={{ flexGrow: 1, display: 'inline-flex', alignItems: 'center' }}>
        <Link href="/" passHref>
          <Box
            title="cafn.co"
            component="a"
            sx={{
              width: '3em',
              color: 'inherit',
              paddingBottom: '3px',
              marginRight: '35px',
            }}
          >
            <LogoCaffeinnne width="100%" height="100%" />
          </Box>
        </Link>
        <nav>
          <NavMenu sections={sections} />
        </nav>
      </Box>
      <Box>
        {/* <IconButton
          sx={{
            'color': 'inherit',
            '&:hover': {
              color: 'white',
            },
          }}
        >
          <SearchIcon />
        </IconButton> */}
        <CartButton />
        <Box sx={{ paddingLeft: 1, display: 'inline-block' }}>
          <UserButton />
        </Box>
      </Box>
    </Toolbar>
  );
};
export default WebHeader;
