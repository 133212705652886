import * as React from 'react';
import { InputAdornment, IconButton } from '@mui/material';
import {
  Remove as RemoveIcon,
  RadioButtonUnchecked as RadioButtonUncheckedIcon,
} from '@mui/icons-material';

import TextField, { TextFieldProps } from '../TextField';

export type PasswordFieldProps = Omit<TextFieldProps, 'type' | 'endAdornment'>;
const PasswordField = (props: PasswordFieldProps): JSX.Element => {
  const [showPassword, setShowPassword] = React.useState(false);
  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };
  const handleMouseDownPassword = (
    event: React.MouseEvent<HTMLButtonElement>,
  ) => {
    event.preventDefault();
  };

  return (
    <TextField
      type={showPassword ? 'text' : 'password'}
      endAdornment={
        <InputAdornment position="end">
          <IconButton
            aria-label="toggle password visibility"
            onClick={handleClickShowPassword}
            onMouseDown={handleMouseDownPassword}
          >
            {showPassword ? <RadioButtonUncheckedIcon /> : <RemoveIcon />}
          </IconButton>
        </InputAdornment>
      }
      {...props}
    />
  );
};
export default PasswordField;
