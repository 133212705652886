import * as React from 'react';
import Link from 'next/link';
import { Box, IconButton, Toolbar } from '@mui/material';
import { useRouter } from 'next/router';

import { navBtnType } from 'types/navBtn';
import { transparentRoutes } from 'styles/route-layout';
import { Menu as MenuIcon, Search as SearchIcon } from 'assets/Icon';
import { LogoCaffeinnne } from 'assets/Branding';
import UserButton from '../UserButton';
import CartButton from '../CartButton';
import HeaderMenuDrawerDyna from '../HeaderMenuDrawer';

export type MobileHeaderProps = {
  sections: navBtnType[];
};
const MobileHeader = (props: MobileHeaderProps): JSX.Element => {
  const { sections } = props;
  const [mobileNavMenuOpen, setMobileNavMenuOpen] = React.useState(false);

  const router = useRouter();

  function toggleMainMenu() {
    return setMobileNavMenuOpen(!mobileNavMenuOpen);
  }

  const toolbarSx = {
    pl: 2,
    pr: 2,
    color: transparentRoutes.has(router.route) ? 'common.white' : 'secondary.main',
    backgroundColor: transparentRoutes.has(router.route) ? 'transparent' : 'common.white',
  };

  return (
    <>
      <Toolbar sx={toolbarSx}>
        <Box
          sx={{
            position: 'absolute',
            zIndex: 20,
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
          }}
        >
          <Link href="/" passHref prefetch={false}>
            <Box
              component="a"
              title="Homepage"
              sx={{
                width: '3em',
                margin: 'auto',
                color: 'inherit',
              }}
            >
              <LogoCaffeinnne width="100%" height="24px" />
            </Box>
          </Link>
        </Box>
        <Box>
          <IconButton
            onClick={toggleMainMenu}
            title="Menu"
            sx={{
              'color': 'inherit',
              '&:hover': {
                color: 'primary.main',
              },
              'marginLeft': '-12px',
            }}
          >
            <MenuIcon />
          </IconButton>
          {/* <IconButton
            sx={{
              'color': 'secondary.main',
              '&:hover': {
                color: 'primary.main',
              },
            }}
          >
            <SearchIcon />
          </IconButton> */}
        </Box>
        <Box sx={{ flexGrow: 1 }} />
        <Box>
          <CartButton />
          <Box sx={{ paddingLeft: 1, display: 'inline-block' }}>
            <UserButton />
          </Box>
        </Box>
      </Toolbar>
      <HeaderMenuDrawerDyna
        sections={sections}
        mobileNavMenuOpen={mobileNavMenuOpen}
        setMobileNavMenuOpen={setMobileNavMenuOpen}
      />
    </>
  );
};
export default MobileHeader;
