import * as React from 'react';
import { Box } from '@mui/material';

import UserModalHeader from '../UserModalHeader';
import SignupForm from '../SignupForm';
import SignupVerification from '../SignupVerification';
import SignupContext from '../SignupContext';

type SignupModalProps = {
  fromCart?: boolean;
};
const SignupModal = (props: SignupModalProps): JSX.Element => {
  const { fromCart } = props;

  const [display, setDisplay] = React.useState('Form');
  const [email, setEmail] = React.useState('');
  const [hash, setHash] = React.useState('');

  return (
    <SignupContext.Provider
      value={{ display, setDisplay, hash, setHash, email, setEmail }}
    >
      <UserModalHeader title="Signup" back="Login" backTitle="Login" />
      <Box sx={{ display: display === 'Form' ? 'block' : 'none' }}>
        <SignupForm fromCart={fromCart} />
      </Box>
      <Box sx={{ display: display === 'Feedback' ? 'block' : 'none' }}>
        <SignupVerification />
      </Box>
    </SignupContext.Provider>
  );
};

export default SignupModal;
