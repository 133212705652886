import { Caffe, CaffePromise } from 'apiclient';

const caffe = new Caffe();

export type lineHandleConfirmProps =
  | {
      action: 'createNew';
      hash?: string;
      usr?: never;
      pwd?: never;
    }
  | {
      action: 'linkUser';
      hash?: string;
      usr: string;
      pwd: string;
    };

export interface lineHandleConfirmReturn {
  redirectUrl: string;
  user: string;
}

const lineHandleConfirm = async (
  props: lineHandleConfirmProps,
): CaffePromise<lineHandleConfirmReturn> => {
  const { action, hash, usr, pwd } = props;

  const actionMap = {
    linkUser: 'link_user',
    createNew: 'create_new',
  };

  return caffe.request({
    method: 'POST',
    url: '/api/method/caff.e.auth.line.handle_confirm',
    data: {
      action: actionMap[action],
      hash,
      usr,
      pwd,
    },
    withCredentials: true,
  });
};
export default lineHandleConfirm;
