import * as React from 'react';
import { CssBaseline } from '@mui/material';

import { Theme } from 'styles';
import { Footer } from 'components/footer';
import { GlobalModalProvider } from 'components/modal';
import { Header } from 'components/header';
import { BackToTop } from 'components/shared';

export type LayoutProps = {
  children: React.ReactNode;
};
const Layout = (props: LayoutProps): JSX.Element => {
  const { children } = props;

  return (
    <Theme>
      <GlobalModalProvider>
        <CssBaseline />
        <Header />
        {children}
        <Footer />
        <BackToTop />
      </GlobalModalProvider>
    </Theme>
  );
};
export default Layout;
