import * as React from 'react';
import { Box, Typography, ButtonBase } from '@mui/material';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';

import { userModalType } from 'types/userModal';
import { FieldBox } from 'components/shared';

export type UserModalHeaderDisplayProps = {
  title: string;
  back?: userModalType;
  backTitle?: string;
  backOnClick: () => void;
};
const UserModalHeaderDisplay = (
  props: UserModalHeaderDisplayProps,
): JSX.Element => {
  const { title, back, backTitle, backOnClick } = props;

  return (
    <FieldBox display="flex" justifyContent="space-between">
      <Box
        display="flex"
        alignItems="center"
        sx={{ paddingBottom: 1, color: 'secondary.main' }}
      >
        <Typography
          variant="subtitle2"
          sx={{ fontWeight: 'fontWeightRegular' }}
        >
          {title}
        </Typography>
      </Box>
      <Box
        display="flex"
        alignItems="center"
        sx={{ paddingBottom: 1, color: 'common.grey' }}
      >
        {back ? (
          <ButtonBase onClick={backOnClick}>
            <>
              <ChevronLeftIcon sx={{ marginTop: '0.1em' }} />
              <Typography
                variant="subtitle2"
                sx={{ fontWeight: 'fontWeightRegular' }}
              >
                {backTitle || back}
              </Typography>
            </>
          </ButtonBase>
        ) : null}
      </Box>
    </FieldBox>
  );
};
export default UserModalHeaderDisplay;
