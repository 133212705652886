import * as React from 'react';
import { Paper, Box, Typography, Switch, Button } from '@mui/material';
import { styled } from '@mui/system';
import Cookies from 'js-cookie';

import { Cookie as CookieIcon } from 'assets/Icon';
import { Terms } from 'components/footer';
import { usePostHog } from 'hook/usePostHog';
import { CookiesConsentStateType, CookiesConsentSetterType } from './CookiesConsentContext';

const BtnPrimary = styled(Button)({
  width: '100%',
  fontWeight: 'bold',
});
const BtnSecondary = styled(Button)({
  'width': '100%',
  'color': '#435244',
  'backgroundColor': '#F2F2F2',
  'fontWeight': 'bold',
  '&:hover': {
    color: '#435244',
    backgroundColor: '#D1CFCD',
  },
});

type CookiesDetailProps = {
  title: string;
  detail: string;
  sss: JSX.Element;
};
const CookiesDetail = (props: CookiesDetailProps): JSX.Element => {
  const { title, detail, sss } = props;
  return (
    <Paper
      variant="outlined"
      elevation={0}
      sx={{
        px: 2,
        py: 1,
      }}
    >
      <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
        <Typography fontWeight="bold">{title}</Typography>
        {sss}
      </Box>
      <Typography variant="caption">{detail}</Typography>
    </Paper>
  );
};

export type CookiesConsentProps = CookiesConsentStateType & CookiesConsentSetterType;
const CookiesConsent = (props: CookiesConsentProps): JSX.Element => {
  const {
    cookiesConsentPerm,
    setCookiesConsentPerm,
    openCookiesConsentDialog,
    setOpenCookiesConsentDialog,
    surpressCookiesConsentDialog,
  } = props;

  const setConsent = (perm: string[]) => {
    // expires in 1 year
    Cookies.set('cc_perm', perm, { expires: 365 });
    setCookiesConsentPerm(perm);
    setOpenCookiesConsentDialog(false);
  };

  const [configMode, setConfigMode] = React.useState(false);
  const [analyticCookies, setAnalyticCookies] = React.useState(false);

  const [openDialog, setOpenDialog] = React.useState(false);
  React.useEffect(() => {
    if (surpressCookiesConsentDialog) {
      setOpenDialog(false);
    } else {
      setOpenDialog(!cookiesConsentPerm || openCookiesConsentDialog);
    }
  }, [cookiesConsentPerm, openCookiesConsentDialog, surpressCookiesConsentDialog]);

  // analytics cookies
  usePostHog(cookiesConsentPerm?.includes('analytics') || false);

  const title = configMode ? 'Cookies preferences' : "Coffee lovers, it's cookies time!";

  return (
    <>
      {openDialog ? (
        <Paper
          sx={{
            position: 'fixed',
            right: '0',
            bottom: '0',
            zIndex: 100,
            maxWidth: {
              xs: 'calc(100vw - 1rem)',
              sm: 'sm',
            },
            p: '1rem',
            m: '0.5rem',
            maxHeight: 'calc(100vh - 1rem)',
            overflowY: 'auto',
            display: 'flex',
            flexDirection: 'column',
          }}
          elevation={5}
        >
          {/* header */}
          <Box
            display="flex"
            sx={{
              justifyContent: 'space-between',
              alignItems: 'center',
            }}
          >
            <Typography fontWeight="bold" color="primary">
              {title}
            </Typography>
            <CookieIcon />
          </Box>
          {/* body */}
          <Box sx={{ overflowY: 'auto' }}>
            {/* description */}
            <Box sx={{ pt: '10px' }}>
              <Typography>
                At cafn.co we uses essential cookies to ensure its proper operation and analytics
                cookies to understand how you interact with it. The latter will be set only after
                consent.
              </Typography>
            </Box>
            {/* config detail */}
            {configMode ? (
              <Box display="flex" sx={{ flexDirection: 'column', rowGap: '10px', pt: '20px' }}>
                <CookiesDetail
                  title="Essential cookies"
                  detail={`
              Essential cookies are required to enable the basic features of this site, such as providing
              secure log-in or adjusting your consent preferences.`}
                  sss={<Switch checked disabled color="primary" />}
                />
                <CookiesDetail
                  title="Analytics cookies"
                  detail={`
            Analytical cookies are used to understand how visitors interact with the website. These cookies help provide information on metrics such as the number of visitors, bounce rate, traffic source, etc.`}
                  sss={
                    <Switch
                      color="primary"
                      checked={analyticCookies}
                      onChange={(event) => {
                        setAnalyticCookies(event.target.checked);
                      }}
                    />
                  }
                />
              </Box>
            ) : null}
          </Box>

          {/* button */}
          <Box display="flex" sx={{ flexDirection: 'column', rowGap: '10px', py: '20px' }}>
            <BtnPrimary
              variant="contained"
              disableElevation
              onClick={() => {
                setConsent(['essential', 'analytics']);
              }}
            >
              Accept all
            </BtnPrimary>
            <BtnSecondary
              variant="contained"
              disableElevation
              onClick={() => {
                setConsent(['essential']);
              }}
            >
              Only accept essential cookies
            </BtnSecondary>
            {configMode ? (
              <BtnSecondary
                variant="contained"
                disableElevation
                onClick={() => {
                  const customConsent = ['essential'];
                  if (analyticCookies) {
                    customConsent.push('analytics');
                  }
                  setConsent(customConsent);
                }}
              >
                Save cookies preferences
              </BtnSecondary>
            ) : (
              <BtnSecondary
                variant="contained"
                disableElevation
                onClick={() => {
                  setConfigMode(true);
                }}
              >
                Configure cookie preference
              </BtnSecondary>
            )}
          </Box>
          {/* footer */}
          <Box>
            <Terms
              footerTerms={[
                { title: 'Privacy notice', href: '/privacy-notice' },
                { title: 'Cookie policy', href: '/cookie-policy' },
              ]}
            />
          </Box>
        </Paper>
      ) : null}
    </>
  );
};
export default CookiesConsent;
