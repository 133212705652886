import * as React from 'react';
import { Avatar, Box, IconButton } from '@mui/material';

import { UserLine as UserLineIcon } from 'assets/Icon';

type HeaderAvatarProps = {
  onClick: React.MouseEventHandler<HTMLButtonElement>;
  alt: string;
  src?: string;
};
const HeaderAvatar: React.FunctionComponent<HeaderAvatarProps> = (props) => {
  const { onClick, alt, src } = props;

  return (
    <IconButton sx={{ p: '4px' }} onClick={onClick}>
      <Avatar
        sx={{
          height: '34px',
          width: '34px',
          border: '1px white solid',
          borderRadius: '50%',
          boxShadow: '0 0 0 2px white',
        }}
        alt={alt}
        src={src}
      />
    </IconButton>
  );
};

export type UserButtonDisplayProps = {
  avatarOnClick: () => void;
  iconOnClick: () => void;
  displayName?: string;
  avatar?: string;
};
const UserButtonDisplay = (props: UserButtonDisplayProps): JSX.Element => {
  const { avatarOnClick, iconOnClick, displayName, avatar } = props;

  const [hasMounted, setHasMounted] = React.useState(false);

  React.useEffect(() => {
    setHasMounted(true);
  }, []);
  if (!hasMounted) {
    return (
      <Box
        sx={{
          p: '4px',
          height: '5px',
          width: '42px',
        }}
      />
    );
  }

  return (
    <>
      {displayName ? (
        <HeaderAvatar alt={displayName} src={avatar} onClick={avatarOnClick} />
      ) : (
        <IconButton
          onClick={iconOnClick}
          sx={{
            'color': 'inherit',
            '&:hover': {
              color: 'inherit',
            },
          }}
        >
          <UserLineIcon width="26px" height="26px" />
        </IconButton>
      )}
    </>
  );
};
export default React.memo(UserButtonDisplay);
