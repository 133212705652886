import * as React from 'react';
import {
  FormControl,
  FormControlProps as MuiFormControlProps,
  InputProps as MuiInputProps,
  TextFieldProps as MuiTextFieldProps,
} from '@mui/material';
import { SxProps } from '@mui/system';

import InputBase from '../InputBase';
import InputLabel from '../InputLabel';
import FormHelperText from '../FormHelperText';
import BoxStyled from '../FieldBox';

export type TextFieldProps = MuiInputProps &
  Pick<MuiFormControlProps, 'required' | 'error' | 'disabled'> &
  Pick<
    MuiTextFieldProps,
    'label' | 'helperText' | 'inputProps' | 'InputProps' | 'InputLabelProps'
  > & { inputSx?: SxProps };
const TextField = (props: TextFieldProps): JSX.Element => {
  const {
    required,
    error,
    disabled,
    label,
    helperText,
    inputRef,
    inputSx,
    InputProps,
    InputLabelProps,
    ...otherProps
  } = props;

  return (
    <BoxStyled>
      <FormControl
        required={required}
        error={error}
        disabled={disabled}
        fullWidth
      >
        {label ? <InputLabel shrink>{label}</InputLabel> : null}

        <InputBase
          sx={inputSx}
          ref={InputProps?.ref}
          inputRef={inputRef}
          inputComponent={InputProps?.inputComponent}
          {...otherProps}
        />

        {helperText ? (
          <FormHelperText>{helperText}</FormHelperText>
        ) : (
          <FormHelperText> </FormHelperText>
        )}
      </FormControl>
    </BoxStyled>
  );
};
export default TextField;
