import * as React from 'react';
import { Container, ContainerProps } from '@mui/material';

export type ModalContainerProps = ContainerProps;
const ModalContainer = React.forwardRef(
  (
    props: ModalContainerProps,
    ref:
      | ((instance: HTMLDivElement | null) => void)
      | React.RefObject<HTMLDivElement>
      | null
      | undefined,
  ) => {
    const { children, maxWidth = 'sm', ...containerProps } = props;

    return (
      <Container
        {...containerProps}
        maxWidth={maxWidth}
        sx={{
          ...{
            backgroundColor: 'common.lightGrey',
            padding: '1rem',
            borderRadius: '5px',
            margin: '1.5rem',
          },
          ...props?.sx,
        }}
        ref={ref}
      >
        {children}
      </Container>
    );
  },
);
ModalContainer.displayName = 'ModalContainer';
export default ModalContainer;
