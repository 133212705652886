import * as React from 'react';
import { useRouter } from 'next/router';
import { Typography, IconButton, Box } from '@mui/material';

import { LineFill as LineIcon } from 'assets/SocialMedia';
import { lineAuth } from 'api';

export type SocialLoginProps = {
  type: 'login' | 'signup';
  fromCart?: boolean;
};

const SocialLogin = (props: SocialLoginProps): JSX.Element => {
  const { type, fromCart } = props;
  const router = useRouter();
  const { query, isReady } = router;
  const [routeBackpath, setRouteBackpath] = React.useState<
    string | undefined
  >();

  React.useEffect(() => {
    if (isReady) {
      setRouteBackpath(
        typeof query?.routeBack === 'string'
          ? `/${query.routeBack}`
          : router.asPath,
      );
    }
  }, [isReady, query]);

  const onClickLineLogin = async () => {
    const redirectUrl = new URL(
      `${process.env.NEXT_PUBLIC_FRONTEND_URL}${routeBackpath}`,
    );
    if (fromCart) {
      redirectUrl.searchParams.append('openCart', 'y');
    }

    const r = await lineAuth({
      redirectUrl: redirectUrl.href,
      forceCreate: type === 'signup',
    });
    if (typeof r === 'string') {
      window.location.replace(r);
    }
  };

  return (
    <Box
      display="flex"
      sx={{
        marginTop: '30px',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <Typography variant="caption">{`Or ${type} with,`}</Typography>
      <IconButton onClick={onClickLineLogin} size="large">
        <LineIcon width="36px" height="36px" />
      </IconButton>
    </Box>
  );
};
export default SocialLogin;
