/* eslint-disable no-underscore-dangle */
/* eslint-disable camelcase */
import axios, {
  AxiosRequestConfig,
  AxiosInstance,
  AxiosResponse,
  AxiosError,
} from 'axios';
import isEqual from 'lodash/isEqual';

import { parseServerMsg } from 'utils/frappeParse';

export type CaffeResponse<T> =
  | T
  | { data: T }
  | { message: T }
  // eslint-disable-next-line camelcase
  | { exc_type: string; _server_messages: string };

export type CaffePromise<T> = Promise<T>;
// return empty dict {}
export type CaffEmptyPromise = Promise<{ [k: string]: never }>;
export type CaffeError = {
  exc?: string;
  exc_type?: string;
  _server_messages?: string;
};

export const CaffeBaseUrl = process.env.NEXT_PUBLIC_BACKEND_URL as string;
export const getCaffeUrl = (url: string): string => `${CaffeBaseUrl}${url}`;

export type CrudParams<T> = {
  type: 'create' | 'read' | 'update' | 'delete';
  doctype: string;
  docname?: string;
  data?: T;
};

export class Caffe {
  axios: AxiosInstance;

  constructor(params?: { debug: boolean }) {
    let debug = false;
    if (params) {
      debug = params.debug;
    }

    this.axios = axios.create({
      baseURL: CaffeBaseUrl,
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
      },
    });
    if (debug) {
      this.axios.interceptors.request.use((request) => {
        // eslint-disable-next-line no-console
        console.debug('Request:', JSON.stringify(request, null, 2));
        return request;
      });
      this.axios.interceptors.response.use((response) => {
        // eslint-disable-next-line no-console
        console.debug('Response:', JSON.stringify(response, null, 2));
        return response;
      });
    }
  }

  request<T = unknown>(params: AxiosRequestConfig): CaffePromise<T> {
    const { ...axiosParams } = params;

    return new Promise((resolve, reject) => {
      this.axios(axiosParams)
        .then((response: AxiosResponse) => {
          let data: T;
          // frappe framework wrap response inside 'message' or 'data' key
          if ('message' in response.data) {
            data = response.data.message;
          } else if (isEqual(Object.keys(response.data), ['data'])) {
            data = response.data.data;
          } else {
            // we'll still resolve data that doesn't match those 2 case
            // since it's still a 200 status code
            // or it could be a blank response
            data = response.data;
          }

          resolve(data);
        })
        .catch((error: Error | AxiosError<CaffeError>): void => {
          if (axios.isAxiosError(error)) {
            // handle axios error response
            // the request that was made to server
            // but server responded with a status code
            // that falls out of the range of 2xx
            if (error.response !== undefined) {
              // this should catch almost all the error msg
              if (error.response.data._server_messages) {
                try {
                  return reject(
                    new Error(
                      parseServerMsg(
                        error.response.data._server_messages,
                      ).message,
                    ),
                  );
                } catch (err) {
                  // ignore since we have another function to handle this
                }
              }

              // this 2 reject is here just in case we don't have _server_message
              // it'll try to extract a bit more information from reponse data
              // don't know how to test this though since can't find a function that won't return _server_messages
              // it really here just in case something wrong and extract a bit more info
              if (error.response.data.exc_type) {
                // should return something like '403 - PermissionError'
                return reject(
                  new Error(
                    `${error.response.status} - ${error.response.data.exc_type}`,
                  ),
                );
              }
              // should return something like '417 - ValidationError'
              return reject(
                new Error(
                  `${error.response.status} - ${error.response.statusText}`,
                ),
              );
            }

            if (error.code !== undefined) {
              return reject(new Error(`Error on caffe - ${error.code}`));
            }

            return reject(new Error('Error on caffe API call'));
          }

          // handle error that is not axios error response
          return reject(new Error(`${error.message} - ${error.name}`));
        })
        .finally(() => {
          // handle unknow case
          reject(new Error('Unexpected caffe API call error'));
        });
    });
  }

  crud<P = unknown, R = unknown>(params: CrudParams<P>): CaffePromise<R> {
    const { type, doctype, docname, data } = params;
    const methodMap = {
      create: 'POST',
      read: 'GET',
      update: 'PUT',
      delete: 'DELETE',
    } as const;

    let url = `/api/resource/${doctype}`;

    if (docname) {
      url = `${url}/${docname}`;
    }

    return this.request({
      method: methodMap[type],
      url,
      data,
      withCredentials: true,
    });
  }
}
export default Caffe;
