import * as React from 'react';
import Link from 'next/link';
import { Box, Breadcrumbs, Typography } from '@mui/material';

import { navBtnHrefType } from 'types/navBtn';

type BreadcrumbItemProps = navBtnHrefType;
const BreadcrumbItem = (props: BreadcrumbItemProps): JSX.Element => {
  const { title, href } = props;

  return (
    <Typography
      variant="body1"
      sx={{ fontWeight: 'fontWeightLight', lineHeight: 1.2 }}
    >
      <Link href={href} passHref prefetch={false}>
        <Box
          component="a"
          title={title}
          sx={{
            'textDecoration': 'none',
            'color': 'common.darkGrey',
            '&:hover': { color: 'primary.main' },
          }}
        >
          {title}
        </Box>
      </Link>
    </Typography>
  );
};

export type TermsProps = {
  footerTerms: BreadcrumbItemProps[];
};
const Terms = (props: TermsProps): JSX.Element => {
  const { footerTerms } = props;

  return (
    <Breadcrumbs
      separator="|"
      aria-label="breadcrumb"
      sx={{ fontWeight: 'fontWeightLight', lineHeight: 1.2 }}
    >
      {footerTerms.map((terms) => (
        <BreadcrumbItem
          key={terms.title}
          title={terms.title}
          href={terms.href}
        />
      ))}
    </Breadcrumbs>
  );
};
export default Terms;
