import * as React from 'react';
import { Box, Typography } from '@mui/material';

import { VerificationEmail } from 'assets/Icon';
import { FieldBox, StyledButton } from 'components/shared';

export type ForgotPassFeedbackDisplayProps = {
  email: string;
  onClickSubmit: () => void;
};
const ForgotPassFeedbackDisplay = (
  props: ForgotPassFeedbackDisplayProps,
): JSX.Element => {
  const { email, onClickSubmit } = props;

  return (
    <>
      <FieldBox>
        <Box display="flex" justifyContent="center">
          <Box
            width="8rem"
            height="8rem"
            paddingBottom={4}
            color="primary.main"
          >
            <VerificationEmail width="100%" height="100%" />
          </Box>
        </Box>
        <Typography
          variant="body1"
          sx={{
            fontWeight: 'fontWeightLight',
            color: 'secondary.main',
            paddingBottom: 3,
          }}
        >
          A Verification email has been sent to this email address{' '}
          <Box component="span" sx={{ color: 'primary.main' }}>
            {email}
          </Box>
          . Please check you email.
        </Typography>
      </FieldBox>
      <StyledButton
        variant="UserForm"
        type="submit"
        onClick={onClickSubmit}
        sx={{ mt: 1 }}
      >
        Ok
      </StyledButton>
    </>
  );
};
export default ForgotPassFeedbackDisplay;
