import * as React from 'react';

import { GlobalModalSetter } from 'components/modal';
import { useUserInfo, useGetCart } from 'composables';
import UserButtonDisplay from './UserButton.display';

const UserButton = (): JSX.Element => {
  const { userModalDispatch, setUserNavMenuOpen } =
    React.useContext(GlobalModalSetter);
  const { data: userInfo } = useUserInfo({});
  const { mutate: mutateCart } = useGetCart({});

  React.useEffect(() => {
    // we need this because when session expired
    // other request return from server will delete cookies
    // at the same time cart will revalidate
    // with cookies before delete,
    // this here will call revalidate again
    // after cookies is changes
    // so that we'll get correct cart data
    mutateCart();
  }, [userInfo, mutateCart]);

  const avatarOnClick = React.useCallback(() => {
    setUserNavMenuOpen(true);
  }, [setUserNavMenuOpen]);

  const iconOnClick = React.useCallback(() => {
    userModalDispatch({
      dialogType: 'Login',
    });
  }, [userModalDispatch]);

  return (
    <UserButtonDisplay
      avatarOnClick={avatarOnClick}
      iconOnClick={iconOnClick}
      displayName={userInfo?.email}
      avatar={userInfo?.avatar}
    />
  );
};
export default UserButton;
