import { getCartReturn } from 'api';

export const countItemInCart = (
  cartData?: getCartReturn,
): number | undefined => {
  let itemCount = 0;
  if (cartData?.length) {
    cartData.forEach((roaster) => {
      const { itemList } = roaster;
      itemCount += itemList.length;
    });

    return itemCount;
  }

  return undefined;
};
