export default function calDrawerWidth(windowsWidth: number | null): number {
  // icon width + left padding + right padding
  const iconWidth = 20 + 12 + 16;

  let drawerWidth = 300;

  let calWindowsWidth = 0;

  if (windowsWidth !== null) {
    calWindowsWidth = windowsWidth;
  }

  // 425 is Mobile L size according to chrome dev tools
  if (calWindowsWidth <= 425) {
    drawerWidth = calWindowsWidth - iconWidth;
  }

  return drawerWidth;
}
