import * as React from 'react';

import { GlobalModalSetter } from 'components/modal';
import UserModalHeaderDisplay, {
  UserModalHeaderDisplayProps,
} from './UserModalHeader.display';

type UserModalHeaderProps = Omit<UserModalHeaderDisplayProps, 'backOnClick'>;
const UserModalHeader: React.FunctionComponent<UserModalHeaderProps> = (
  props: UserModalHeaderProps,
) => {
  const { title, back, backTitle } = props;
  const { userModalDispatch } = React.useContext(GlobalModalSetter);

  const backOnClick = () => {
    if (back) {
      userModalDispatch({ dialogType: back });
    }
  };

  return (
    <UserModalHeaderDisplay
      title={title}
      back={back}
      backTitle={backTitle}
      backOnClick={backOnClick}
    />
  );
};
export default UserModalHeader;
