import * as React from 'react';

import UserModalHeader from '../UserModalHeader';
import LoginForm from '../LoginForm';

type LoginModalProps = {
  onLoginSucceed?: () => void;
  fromCart?: boolean;
};
const LoginModal = (props: LoginModalProps): JSX.Element => {
  const { fromCart, onLoginSucceed } = props;
  return (
    <>
      <UserModalHeader title="Login" back="Signup" backTitle="Signup" />
      <LoginForm fromCart={fromCart} onLoginSucceed={onLoginSucceed} />
    </>
  );
};

export default LoginModal;
