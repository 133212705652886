import * as React from 'react';

import ForgotPassFormDisplay from './ForgotPassForm.display';
import ForgotPassContext from '../ForgotPassContext';

const ForgotPassForm = (): JSX.Element => {
  const { setDisplay, setEmail } = React.useContext(ForgotPassContext);

  const setDisplayContext = () => {
    setDisplay('Feedback');
  };

  const setEmailContext = (email: string) => {
    setEmail(email);
  };

  return (
    <ForgotPassFormDisplay
      setEmailContext={setEmailContext}
      setDisplayContext={setDisplayContext}
    />
  );
};
export default ForgotPassForm;
