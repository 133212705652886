import { Caffe, CaffePromise } from 'apiclient';

import { User } from 'types/user';

const caffe = new Caffe();

export type createUserProps = {
  email: string;
  password: string;
  fromCart?: boolean;
};

export type createUserReturn = {
  email: string;
  hash: string;
};

const createUser = async (
  props: createUserProps,
): CaffePromise<createUserReturn> => {
  return caffe.request({
    method: 'POST',
    url: '/api/method/caff.e.auth.email.create_user',
    data: {
      email: props.email,
      password: props.password,
      from_cart: props.fromCart,
    },
    // with credentials true to force send cookies
    withCredentials: true,
  });
};
export default createUser;
