import * as React from 'react';
import { useFormik, FormikValues } from 'formik';
import * as Yup from 'yup';
import { Grid, Typography } from '@mui/material';
import posthog from 'posthog-js';

import { checkDuplicateUser } from 'api';
import {
  TextField,
  PasswordField,
  StyledButton,
  FieldBox,
} from 'components/shared';
import { createUser } from 'api/user';
import SignupContext from '../SignupContext';
import SocialLogin from '../SocialLogin';

type SignupFormType = {
  fromCart?: boolean;
};
const SignupForm = (props: SignupFormType): JSX.Element => {
  type SignupFormikValues = {
    formError?: string;
  } & FormikValues;

  const { fromCart } = props;

  const { setDisplay, setEmail, setHash } = React.useContext(SignupContext);

  const formik = useFormik<SignupFormikValues>({
    initialValues: {
      email: '',
      password: '',
    },
    validationSchema: Yup.object({
      email: Yup.string()
        .email('Invalid email address - Please check and try again')
        .test(
          'email-exists',
          'Email already exists',
          async (value: string | undefined) => {
            if (typeof value === 'undefined') {
              return true;
            }

            const isDuplicate = await checkDuplicateUser(value);

            return !isDuplicate;
          },
        )
        .required('Please enter an email address'),
      password: Yup.string().required('Please enter a password'),
    }),

    onSubmit: async (v, { setSubmitting, setErrors }) => {
      setSubmitting(true);

      try {
        const r = await createUser({
          email: v.email,
          password: v.password,
          fromCart,
        });
        // eslint-disable-next-line no-console
        setHash(r.hash);
        setEmail(r.email);
        setDisplay('Feedback');
        posthog.identify(r.email);
      } catch (err: any) {
        if (typeof err === 'object' && err !== null && 'message' in err) {
          if (
            err.message.includes(
              'Password too simple. Please try something more complex.',
            )
          ) {
            setErrors({
              password: 'Password is too weak - Try adding more characters',
            });
            return;
          }
        }

        setErrors({
          formError: 'Something went wrong',
        });
      } finally {
        setSubmitting(false);
      }
    },
  });

  return (
    <form onSubmit={formik.handleSubmit}>
      <Grid container>
        <Grid item xs={12}>
          <TextField
            name="email"
            label="Email Address"
            placeholder="Email Address"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.email}
            error={formik.touched.email && Boolean(formik.errors.email)}
            helperText={formik.touched.email && formik.errors.email}
            disabled={formik.isSubmitting}
            autoComplete="email"
          />
        </Grid>
        <Grid item xs={12}>
          <PasswordField
            name="password"
            label="Password"
            placeholder="Minimum 6 characters with a number and a letter"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.password}
            error={formik.touched.password && Boolean(formik.errors.password)}
            helperText={formik.touched.password && formik.errors.password}
            disabled={formik.isSubmitting}
            autoComplete="new-password"
          />
        </Grid>
        <Grid item xs={12}>
          {formik.errors.formError ? (
            <FieldBox
              sx={{
                'paddingLeft': 1,
                '& p': {
                  color: 'error.main',
                  fontWeight: 'fontWeightLight',
                },
              }}
            >
              <Typography variant="body2">{formik.errors.formError}</Typography>
            </FieldBox>
          ) : null}
          <StyledButton
            variant="UserForm"
            disabled={formik.isSubmitting}
            type="submit"
            sx={{
              mt: 3,
            }}
          >
            Sign Up
          </StyledButton>
          <SocialLogin type="signup" fromCart={fromCart} />
        </Grid>
      </Grid>
    </form>
  );
};

export default SignupForm;
