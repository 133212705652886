import * as React from 'react';
import { IconButton, Badge } from '@mui/material';

import { MyBox as MyBoxIcon } from 'assets/Icon';

export type CartButtonDisplayProps = {
  onClick?: () => void;
  badgeContent?: number;
};
const CartButtonDisplay = (props: CartButtonDisplayProps): JSX.Element => {
  const { onClick, badgeContent } = props;

  return (
    <>
      <IconButton
        onClick={onClick}
        sx={{
          'color': 'inherit',
          '&:hover': {
            color: 'inherit',
          },
        }}
      >
        <Badge badgeContent={badgeContent}>
          <MyBoxIcon width="26px" height="26px" />
        </Badge>
      </IconButton>
    </>
  );
};
export default CartButtonDisplay;
