import { Caffe, CaffePromise } from 'apiclient';

const caffe = new Caffe();

export type validateUserProps = {
  token: string;
  hash: string;
};

export type validateUserReturn = {
  email: string;
  displayName: string;
  avatar: string;
};

const validateUser = async (
  props: validateUserProps,
): CaffePromise<validateUserReturn> => {
  return caffe.request({
    method: 'POST',
    url: '/api/method/caff.e.auth.email.validate_user',
    params: props,
    withCredentials: true,
  });
};
export default validateUser;
