/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import * as React from 'react';
import { Typography } from '@mui/material';

const Copyright = (): JSX.Element => {
  return (
    <Typography variant="body1" sx={{ fontWeight: 'fontWeightLight' }}>
      © 2020 Caffeine. All rights reserved.
    </Typography>
  );
};
export default Copyright;
