import * as React from 'react';
import Link from 'next/link';
import { Box, List, ListItem, ListItemText } from '@mui/material';

import { navBtnHrefType } from 'types/navBtn';

export type MainFooterItemProps = {
  title: string;
  itemHeader?: boolean;
  href?: string;
};
export const MainFooterItem = (props: MainFooterItemProps): JSX.Element => {
  const { title, itemHeader = false, href } = props;

  return (
    <ListItem disableGutters sx={{ paddingTop: 0, paddingBottom: 0 }}>
      <ListItemText
        primaryTypographyProps={{
          variant: 'body1',
        }}
        sx={
          itemHeader
            ? { color: 'common.grey' }
            : { fontWeight: 'fontWeightLight' }
        }
      >
        {!href ? (
          title
        ) : (
          <Link href={href} passHref prefetch={false}>
            <Box
              component="a"
              title={title}
              sx={{
                'color': 'common.darkGrey',
                'fontWeight': itemHeader ? 600 : undefined,
                'textDecoration': 'none',
                '&:hover': {
                  color: 'primary.main',
                },
              }}
            >
              {title}
            </Box>
          </Link>
        )}
      </ListItemText>
    </ListItem>
  );
};

export type MainFooterNavProps = {
  mainNav: navBtnHrefType[];
};
export const MainFooterNav = (props: MainFooterNavProps): JSX.Element => {
  const { mainNav } = props;

  return (
    <List dense>
      {mainNav.map((nav) => (
        <MainFooterItem key={nav.title} title={nav.title} href={nav.href} />
      ))}
    </List>
  );
};

export const CoffeeBeansNav = (): JSX.Element => {
  return (
    <List dense>
      <MainFooterItem title="Coffee Beans" href="/coffee-beans" itemHeader />
      <MainFooterItem title="Light Roasted" href="/coffee-beans/light-roast" />
      <MainFooterItem
        title="Medium Roasted"
        href="/coffee-beans/medium-roast"
      />
      <MainFooterItem title="Dark Roasted" href="/coffee-beans/dark-roast" />
      <MainFooterItem title="Arabica" href="/coffee-beans/arabica" />
      <MainFooterItem title="Robusta" href="/coffee-beans/robusta" />
    </List>
  );
};

export const UserFooterNav = (): JSX.Element => {
  return (
    <List dense>
      <MainFooterItem title="For Caffeinnne Users" itemHeader />
      <MainFooterItem title="Caffeinnne coins" />
      <MainFooterItem title="Caffeinnne user ranking" />
    </List>
  );
};

export const RoasterFooterNav = (): JSX.Element => {
  return (
    <List dense>
      <MainFooterItem title="For Roasters" itemHeader />
      <MainFooterItem title="Join our roaster community" />
      <MainFooterItem title="Seller Center" />
    </List>
  );
};
