import * as React from 'react';

export type FeedbackModalStateType = {
  feedbackModalOpen: boolean;
};

export type FeedbackModalSetterType = {
  setFeedbackModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
};

export const defaultFeedbackModalSetter = {
  setFeedbackModalOpen: (): null => null,
};

export const useFeedbackModal = (): {
  state: FeedbackModalStateType;
  setter: FeedbackModalSetterType;
} => {
  const [feedbackModalOpen, setFeedbackModalOpen] = React.useState(false);

  return {
    state: {
      feedbackModalOpen,
    },
    setter: {
      setFeedbackModalOpen,
    },
  };
};
