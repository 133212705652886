import * as React from 'react';
import dynamic from 'next/dynamic';
import { useRouter } from 'next/router';

import { removeQueryParamsFromRouter } from 'utils/queryString';
import { CartDrawerSetterType, CartDrawerStateType } from './CartDrawerContext';

const DynamicCartDrawer = dynamic(() => import('./CartDrawer'));

type CartDrawerDynaType = CartDrawerStateType & CartDrawerSetterType;
const CartDrawerDyna = (props: CartDrawerDynaType): JSX.Element => {
  const { cartDrawerOpen, setCartDrawerOpen } = props;

  const router = useRouter();
  const { openCart } = router.query;

  React.useEffect(() => {
    if (openCart === 'y') {
      setCartDrawerOpen(true);
      removeQueryParamsFromRouter(router, ['openCart']);
    }
  }, [openCart, setCartDrawerOpen]);

  if (cartDrawerOpen) {
    return <DynamicCartDrawer {...props} />;
  }
  return <></>;
};
export default React.memo(CartDrawerDyna);
