import { userModalType } from 'types/userModal';

export type userModalStateType = {
  type: userModalType;
  detail?: {
    disableClose?: boolean;
    fromCart?: boolean;
    onLoginSucceed?: () => void;
  };
};

export type userModalActionType =
  | {
      // shortcut to open dialog without detail config
      dialogType: userModalType;
      type?: never;
      payload?: never;
    }
  | {
      dialogType?: never;
      type: 'open';
      payload: userModalStateType;
    }
  | {
      dialogType?: never;
      type: 'close';
      payload?: never;
    };

export const userModalReducer = (
  state: userModalStateType,
  action: userModalActionType,
): userModalStateType => {
  const { dialogType, type, payload } = action;

  const defaultDetail = {
    disableClose: false,
  };

  if (dialogType) {
    return {
      type: dialogType,
      detail: {
        ...defaultDetail,
        ...state.detail,
      },
    };
  }

  switch (type) {
    case 'open':
      return {
        type: payload.type,
        detail: {
          ...defaultDetail,
          ...state.detail,
          ...payload.detail,
        },
      };

    case 'close':
      return {
        type: 'None',
      };

    default:
      return {
        type: 'None',
      };
  }
};
