import * as React from 'react';
import { Box, Typography } from '@mui/material';
import { useRouter } from 'next/router';

import { StyledButton, FieldBox } from 'components/shared';
import { GlobalModalSetter } from 'components/modal';
import { PositiveLine } from 'assets/Icon';

const ResetPassFeedback = (): JSX.Element => {
  const { userModalDispatch } = React.useContext(GlobalModalSetter);
  const router = useRouter();
  const [freeze, setFreeze] = React.useState(false);

  return (
    <>
      <FieldBox>
        <Box display="flex" justifyContent="center">
          <Box
            width="8rem"
            height="8rem"
            paddingBottom={4}
            color="primary.main"
          >
            <PositiveLine width="100%" height="100%" />
          </Box>
        </Box>
        <Typography
          sx={{
            fontWeight: 'fontWeightLight',
            color: 'secondary.main',
            paddingBottom: 3,
          }}
          variant="body1"
        >
          You have successfully reset the password.
        </Typography>
      </FieldBox>
      <StyledButton
        variant="UserForm"
        type="submit"
        onClick={async () => {
          setFreeze(true);
          await router.push('/');
          userModalDispatch({
            type: 'open',
            payload: {
              type: 'Login',
              detail: {
                disableClose: false,
              },
            },
          });
        }}
        disabled={freeze}
        sx={{ mt: 1 }}
      >
        Back to Login
      </StyledButton>
    </>
  );
};
export default ResetPassFeedback;
