import * as React from 'react';
import {
  createTheme,
  responsiveFontSizes,
  ThemeProvider,
  ThemeOptions,
} from '@mui/material/styles';

declare module '@mui/material/styles/createTypography' {
  interface FontStyle {
    fontFamilySerif: React.CSSProperties['fontFamily'];
    fontFamilyThaiSerif: React.CSSProperties['fontFamily'];
    fontFamilySansSerif: React.CSSProperties['fontFamily'];
  }
  interface FontStyleOptions {
    fontFamilySerif: React.CSSProperties['fontFamily'];
    fontFamilyThaiSerif: React.CSSProperties['fontFamily'];
    fontFamilySansSerif: React.CSSProperties['fontFamily'];
  }
}

declare module '@mui/material/styles/createPalette' {
  interface CommonColors {
    lightGrey: string;
    grey: string;
    darkGrey: string;
  }
}

const fontFamily = '"Tenor Sans", "Kanit", serif';
const serifFont = '"Tenor Sans", "Kanit", serif';
const thaiSerifFont = '"Kanit", serif';
const sansSerifFont = '"Kumbh Sans", "Kanit", sans-serif';

const themeObject: ThemeOptions = {
  spacing: (factor: number) => `${0.5 * factor}rem`,
  typography: {
    fontWeightLight: 300,
    fontWeightRegular: 400,
    fontWeightMedium: 500,
    fontWeightBold: 700,
    fontFamily,
    fontFamilySerif: serifFont,
    fontFamilyThaiSerif: thaiSerifFont,
    fontFamilySansSerif: sansSerifFont,
    h1: {
      fontFamily: serifFont,
      fontWeight: 400,
      fontSize: '4.6rem',
    },
    h2: {
      fontFamily: serifFont,
      fontWeight: 400,
      fontSize: '3.6rem',
    },
    h3: {
      fontFamily: serifFont,
      fontWeight: 400,
      fontSize: '2.8rem',
    },
    h4: {
      fontFamily: serifFont,
      fontWeight: 400,
      fontSize: '2.2rem',
    },
    h5: {
      fontFamily: sansSerifFont,
      fontWeight: 400,
      fontSize: '1.8rem',
    },
    h6: {
      fontFamily: sansSerifFont,
      fontWeight: 400,
      fontSize: '1.6rem',
    },
    subtitle1: {
      fontFamily: sansSerifFont,
      fontWeight: 400,
      fontSize: '1.3rem',
    },
    subtitle2: {
      fontFamily: sansSerifFont,
      fontWeight: 400,
      fontSize: '1.15rem',
    },
    body1: {
      fontFamily: sansSerifFont,
      fontWeight: 400,
      fontSize: '1rem',
    },
    body2: {
      fontFamily: sansSerifFont,
      fontWeight: 400,
      fontSize: '0.875rem',
    },
    button: {
      fontFamily: sansSerifFont,
      fontWeight: 500,
      fontSize: '0.875rem',
      textTransform: 'unset',
    },
    caption: {
      fontFamily: sansSerifFont,
      fontWeight: 300,
      fontSize: '0.8rem',
    },
    overline: {
      fontFamily: sansSerifFont,
      fontWeight: 400,
      fontSize: '0.75rem',
      textTransform: 'uppercase',
    },
  },
  palette: {
    common: {
      black: '#000',
      lightGrey: '#F2F2F2',
      grey: '#D1CFCD',
      darkGrey: '#848484',
      white: '#fff',
    },
    primary: {
      main: '#435244',
    },
    secondary: {
      main: '#3F4044',
    },
    error: {
      main: '#FE3831',
    },
    warning: {
      main: '#FF9800',
    },
    info: {
      main: '#2196F3',
    },
    success: {
      main: '#297E6A',
    },
    text: { primary: '#3F4044', secondary: '#757575' },
  },
  components: {
    MuiBadge: {
      styleOverrides: {
        badge: {
          fontFamily: sansSerifFont,
        },
      },
    },
  },
};

export const theme = responsiveFontSizes(createTheme(themeObject));

type ThemeProps = {
  children: React.ReactNode;
};
export default function Theme(props: ThemeProps): JSX.Element {
  const { children } = props;

  return <ThemeProvider theme={theme}>{children}</ThemeProvider>;
}
