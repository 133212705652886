import * as React from 'react';

export type CartDrawerStateType = {
  cartDrawerOpen: boolean;
};

export type CartDrawerSetterType = {
  setCartDrawerOpen: React.Dispatch<React.SetStateAction<boolean>>;
};

export const defaultCartDrawerSetter = {
  setCartDrawerOpen: (): null => null,
};

export const useCartDrawer = (): {
  state: CartDrawerStateType;
  setter: CartDrawerSetterType;
} => {
  const [cartDrawerOpen, setCartDrawerOpen] = React.useState(false);

  return {
    state: {
      cartDrawerOpen,
    },
    setter: {
      setCartDrawerOpen,
    },
  };
};
