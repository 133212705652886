import * as React from 'react';
import { useRouter } from 'next/router';
import posthog from 'posthog-js';

import { logout } from 'api';
import { useGetCart, useUserInfo } from 'composables';
import UserDrawerDisplay, {
  UserDrawerDisplayProps,
} from './UserDrawer.display';
import { UserDrawerStateType, UserDrawerSetterType } from './UserDrawerContext';

type UserDrawerProps = UserDrawerStateType & UserDrawerSetterType;
const UserDrawer = (props: UserDrawerProps): JSX.Element => {
  const { userNavMenuOpen, setUserNavMenuOpen } = props;
  const router = useRouter();

  const { mutate: mutateUserInfo } = useUserInfo({});
  const { mutate: mutateCart } = useGetCart({});

  const onLogout = React.useCallback(async () => {
    await logout();
    posthog.reset();
    mutateCart();
    mutateUserInfo();
    router.push('/');
  }, []);

  const menuList: UserDrawerDisplayProps['menuList'] = [
    // {
    //   title: 'My Account',
    //   href: '#',
    //   variant: 'h5',
    // },
    // {
    //   title: 'Profile',
    //   href: '#',
    //   variant: 'subtitle1',
    // },
    // {
    //   title: 'Address',
    //   href: '#',
    //   variant: 'subtitle1',
    // },
    // {
    //   title: 'Payment',
    //   href: '#',
    //   variant: 'subtitle1',
    // },
    {
      title: 'My Purchases',
      href: '/user/purchase',
      variant: 'h5',
    },
    // {
    //   title: 'My Wishlist',
    //   href: '#',
    //   variant: 'h5',
    // },
    {
      title: 'My Cup of Coffee',
      href: '/user/my-cup-of-coffee',
      variant: 'h5',
    },
    {
      title: 'Logout',
      variant: 'h5',
      onClick: onLogout,
    },
  ];

  const toggleMainMenu = React.useCallback(() => {
    return setUserNavMenuOpen((v) => !v);
  }, [setUserNavMenuOpen]);

  return (
    <UserDrawerDisplay
      menuList={menuList}
      userDrawerOpen={userNavMenuOpen}
      toggleMainMenu={toggleMainMenu}
    />
  );
};
export default React.memo(UserDrawer);
