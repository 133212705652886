/*
Server side rendering config
more info - https://material-ui.com/guides/server-rendering
copied from - https://github.com/mui-org/material-ui/blob/v5.0.0-beta.4/examples/nextjs-with-typescript/pages/_document.tsx
*/

import * as React from 'react';
import Head from 'next/head';
// for using vconsole (liff debugging)
// import Script from 'next/script';
import { AppProps } from 'next/app';
import { DefaultSeo } from 'next-seo';
import { LocalizationProvider as MuiLocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import {
  CacheProvider as EmotionCacheProvider,
  EmotionCache,
} from '@emotion/react';

import 'styles/css';
import { Layout } from 'components/template';
import { createEmotionCache } from 'styles';

// enable api mocks
// if (process.env.NEXT_PUBLIC_API_MOCKING === 'enabled') {
//   // eslint-disable-next-line global-require
//   require('../../mocks');
// }

// Client-side cache, shared for the whole session of the user in the browser.
const clientSideEmotionCache = createEmotionCache();

type MyAppProps = AppProps & {
  emotionCache?: EmotionCache;
};

export default function MyApp(props: MyAppProps): JSX.Element {
  const { Component, pageProps, emotionCache = clientSideEmotionCache } = props;

  const title = 'cafn.co | Roasted Coffee Beans from Passionate Roasters';
  const description =
    'แพลตฟอร์มเมล็ดกาแฟที่มากกว่าการซื้อขาย สนับสนุนทุกความหลงใหลในกาแฟ เชื่อมโยงคนรักการดื่มกาแฟกับคนคั่วเมล็ดกาแฟ และทำให้เรื่องกาแฟง่ายและมีความสุขอยู่เสมอ';

  return (
    <>
      {/* for liff app debugging */}
      {/* <Script
        src="https://unpkg.com/vconsole@latest/dist/vconsole.min.js"
        onLoad={() => {
          const vConsole = new window.VConsole();
        }}
      /> */}
      <DefaultSeo
        title={title}
        description={description}
        openGraph={{
          type: 'website',
          locale: 'th_TH',
          site_name: 'cafn.co',
          title,
          description,
          images: [
            {
              url: 'https://ik.imagekit.io/caffeinnne/img/cafn_meta.jpg',
              width: 1200,
              height: 630,
              alt: 'cafn.co image',
            },
          ],
        }}
        twitter={{
          handle: '@cafnco',
          site: '@cafnco',
          cardType: 'summary_large_image',
        }}
      />
      <EmotionCacheProvider value={emotionCache}>
        <MuiLocalizationProvider dateAdapter={AdapterDateFns}>
          <Head>
            <meta
              name="viewport"
              content="initial-scale=1, width=device-width"
            />
          </Head>
          <Layout>
            <Component {...pageProps} />
          </Layout>
        </MuiLocalizationProvider>
      </EmotionCacheProvider>
    </>
  );
}
