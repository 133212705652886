import * as React from 'react';

import { GlobalModalSetter } from 'components/modal';
import ForgotPassFeebackDisplay from './ForgotPassFeedback.display';
import ForgotPassContext from '../ForgotPassContext';

const ForgotFeedback: React.VoidFunctionComponent = () => {
  const { email } = React.useContext(ForgotPassContext);
  const { userModalDispatch } = React.useContext(GlobalModalSetter);

  const onClickSubmit = () => {
    userModalDispatch({ type: 'close' });
  };

  return (
    <ForgotPassFeebackDisplay email={email} onClickSubmit={onClickSubmit} />
  );
};
export default ForgotFeedback;
