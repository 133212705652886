import * as React from 'react';
import { useFormik, FormikValues } from 'formik';
import * as Yup from 'yup';
import { Box, Typography } from '@mui/material';
import { useRouter } from 'next/router';

import { PasswordField, StyledButton, FieldBox } from 'components/shared';
import { changePassword } from 'api/user';
import ResetPassContext from '../ResetPassContext';

const ResetPassForm = (): JSX.Element => {
  type ResetPassFormikValues = {
    formError?: string;
  } & FormikValues;
  const { setDisplay } = React.useContext(ResetPassContext);

  const router = useRouter();
  const {
    query: { email, hash },
  } = router;

  const formik = useFormik<ResetPassFormikValues>({
    initialValues: {
      password: '',
    },
    validationSchema: Yup.object({
      password: Yup.string().required('No password provided'),
    }),

    onSubmit: async (v, { setSubmitting, setErrors }) => {
      setSubmitting(true);

      try {
        await changePassword({
          newPassword: v.password,
          key: hash as string,
        });
      } catch (err: any) {
        setSubmitting(false);
        if (typeof err === 'object' && err !== null && 'message' in err) {
          if (
            err.message.includes(
              'Password too simple. Please try something more complex.',
            )
          ) {
            setErrors({
              password: 'Password is too weak - Try adding more characters',
            });
            return;
          }
        }
        setErrors({
          formError: 'Something went wrong',
        });
        return;
      }
      setDisplay('Feedback');
    },
  });

  return (
    <form onSubmit={formik.handleSubmit}>
      <FieldBox>
        <Typography
          variant="body1"
          sx={{
            fontWeight: 'fontWeightLight',
            color: 'secondary.main',
            paddingBottom: 3,
          }}
        >
          Please enter the new password for{' '}
          <Box component="span" sx={{ color: 'primary.main' }}>
            {email}
          </Box>
        </Typography>
      </FieldBox>
      <PasswordField
        name="password"
        label="Password"
        placeholder="Please enter your password"
        onChange={formik.handleChange}
        onBlur={formik.handleBlur}
        value={formik.values.password}
        error={formik.touched.password && Boolean(formik.errors.password)}
        helperText={
          formik.touched.password && formik.errors.password !== '-'
            ? formik.errors.password
            : undefined
        }
        disabled={formik.isSubmitting}
      />
      {formik.errors.formError ? (
        <FieldBox
          sx={{
            'paddingLeft': 1,
            '& p': {
              color: 'error.main',
              fontWeight: 'fontWeightLight',
            },
          }}
        >
          <Typography variant="body2">{formik.errors.formError}</Typography>
        </FieldBox>
      ) : null}
      <StyledButton
        variant="UserForm"
        type="submit"
        disabled={formik.isSubmitting}
        sx={{ mt: 3 }}
      >
        Next
      </StyledButton>
    </form>
  );
};
export default ResetPassForm;
