import { Caffe, CaffePromise } from 'apiclient';
import { AxiosRequestConfig } from 'axios';

const caffe = new Caffe();

export type getCouponDetailProps = {
  pCn?: string | string[] | undefined;
  sCn?: string | string[] | undefined;
  sCo?: string | string[] | undefined;
} & {
  axiosConfig?: AxiosRequestConfig;
};

export type CouponDetail = {
  couponDetail: 'found';
  title: string;
  detail: string;
  img: string;
};

export type getCouponDetailReturn = {
  platformCouponDetail?: CouponDetail;
  shopCouponDetail?: CouponDetail;
};

const getCouponDetail = async (
  props: getCouponDetailProps,
): CaffePromise<getCouponDetailReturn> => {
  const { axiosConfig, pCn, sCn, sCo } = props;

  return caffe.request({
    ...{
      method: 'GET',
      url: '/api/method/caff.e.coupon.query.get_coupon_detail',
      params: {
        p_cn: pCn,
        s_cn: sCn,
        s_co: sCo,
      },
    },
    ...axiosConfig,
  });
};
export default getCouponDetail;
