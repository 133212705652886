import * as React from 'react';

import { useGetCart } from 'composables';
import { GlobalModalSetter } from 'components/modal';
import { countItemInCart } from './CartButtonHelper';
import CartButtonDisplay from './CartButton.display';

const CartButton: React.FunctionComponent = () => {
  const { setCartDrawerOpen } = React.useContext(GlobalModalSetter);
  const { data: cartData } = useGetCart({});

  return (
    <CartButtonDisplay
      onClick={() => {
        setCartDrawerOpen(true);
      }}
      badgeContent={countItemInCart(cartData)}
    />
  );
};
export default CartButton;
