import * as React from 'react';
import Box from '@mui/material/Box';
import { SwipeableDrawer, SwipeableDrawerProps } from '@mui/material';

import calDrawerWidth from 'utils/size';
import useWindowDimensions from 'hook/useWindowDimensions';

export type DrawerType = {
  widthVariant?: 'fullWidth' | 'partial';
} & SwipeableDrawerProps;
const Drawer = (props: DrawerType): JSX.Element => {
  const { children, widthVariant = 'partial', ...swipeableDrawerProps } = props;

  const style: React.CSSProperties = {
    height: '100%',
  };
  function GetDrawerWidth() {
    const { width } = useWindowDimensions();
    return calDrawerWidth(width);
  }
  if (widthVariant === 'partial') {
    style.width = GetDrawerWidth();
  } else if (widthVariant === 'fullWidth') {
    style.width = '100vw';
    style.maxWidth = '480px';
  }

  return (
    <SwipeableDrawer {...swipeableDrawerProps}>
      <Box style={style}>{children}</Box>
    </SwipeableDrawer>
  );
};
export default Drawer;
