import * as React from 'react';
import dynamic from 'next/dynamic';

import { navBtnType } from 'types/navBtn';
import {
  HeaderMenuDrawerSetterType,
  HeaderMenuDrawerStateType,
} from './HeaderMenuDrawerContext';

const DynamicHeaderMenuDrawer = dynamic(() => import('./HeaderMenuDrawer'));

type HeaderMenuDrawerDynaType = HeaderMenuDrawerStateType &
  HeaderMenuDrawerSetterType & { sections: navBtnType[] };
const HeaderMenuDrawerDyna = (props: HeaderMenuDrawerDynaType): JSX.Element => {
  const { mobileNavMenuOpen } = props;

  if (mobileNavMenuOpen) {
    return <DynamicHeaderMenuDrawer {...props} />;
  }
  return <></>;
};
export default React.memo(HeaderMenuDrawerDyna);
