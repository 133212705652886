import useSWR, { KeyedMutator } from 'swr';

import { getCart, getCartReturn } from 'api';

const useGetCart = (args: {
  initialData?: getCartReturn;
}): {
  isLoading: boolean;
  isError: boolean;
  data?: getCartReturn;
  mutate: KeyedMutator<getCartReturn>;
} => {
  const { initialData } = args;

  const { data, error, mutate } = useSWR(
    {
      ...{ api: 'useGetCart' },
    },
    getCart,
    {
      fallbackData: initialData,
    },
  );

  return {
    isLoading: !error && !data,
    isError: error,
    data,
    mutate,
  };
};
export default useGetCart;
