import { styled } from '@mui/material/styles';
import { InputLabel } from '@mui/material';

const InputLabelStyled = styled(InputLabel)(({ theme }) => ({
  color: theme.palette.secondary.main,
  fontWeight: theme.typography.fontWeightLight,
  marginLeft: '3px',
  transform: 'translate(0, 0) scale(0.75)',
}));
export default InputLabelStyled;
