import * as React from 'react';
import Link from 'next/link';
import {
  Box,
  ButtonBase,
  List,
  ListItem,
  ListItemText,
  Typography,
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';

import { useUserInfo } from 'composables';
import { Drawer } from 'components/shared';
import { Close as CloseIcon } from 'assets/Icon';

type menuOption =
  | {
      title: string;
      variant: 'h5' | 'subtitle1';
      onClick?: never;
      href: string;
    }
  | {
      title: string;
      variant: 'h5' | 'subtitle1';
      onClick: () => void;
      href?: never;
    };

export type UserDrawerDisplayProps = {
  menuList: menuOption[];
  userDrawerOpen: boolean;
  toggleMainMenu: () => void;
};
const UserDrawerDisplay = (props: UserDrawerDisplayProps): JSX.Element => {
  const { menuList, userDrawerOpen, toggleMainMenu } = props;

  const theme = useTheme();
  const desktop = useMediaQuery(theme.breakpoints.up('md'));
  const { data: userInfo } = useUserInfo({});

  return (
    <Drawer
      anchor="right"
      open={userDrawerOpen}
      onClose={toggleMainMenu}
      onOpen={toggleMainMenu}
    >
      <Box
        display="flex"
        alignItems="center"
        sx={{ pl: 2, pr: 2, pt: 4, pb: 2 }}
      >
        <Box display="flex" flexGrow={1}>
          {/* if line user, use displayname, else use email
          this is temporary, please change this after implement user profile */}
          <Typography>
            {(userInfo?.email || '').match(/@line\.me/g)
              ? userInfo?.displayName
              : userInfo?.email}
          </Typography>
        </Box>
        <Box display="flex" flexGrow={0}>
          <ButtonBase onClick={toggleMainMenu}>
            <CloseIcon width="1em" height="1em" />
          </ButtonBase>
        </Box>
      </Box>
      <List>
        {menuList.map((menu) => {
          let textVariant = menu.variant as
            | 'h5'
            | 'h6'
            | 'subtitle1'
            | 'subtitle2';

          if (desktop) {
            if (menu.variant === 'h5') {
              textVariant = 'h6';
            } else {
              textVariant = 'subtitle2';
            }
          }

          const ListText = (
            <ListItemText
              primary={menu.title}
              primaryTypographyProps={{
                variant: textVariant,
                style: {
                  fontFamily: 'h5.fontFamily',
                  lineHeight: menu.variant !== 'h5' ? 1.4 : 1.6,
                  paddingBottom: menu.variant !== 'h5' ? undefined : '0.25em',
                  paddingTop: menu.variant !== 'h5' ? undefined : '0.25em',
                },
              }}
              sx={{
                'color': 'palette.secondary.main',
                '&:hover': {
                  color: 'palette.primary.main',
                },
              }}
            />
          );

          if (menu.href) {
            return (
              <Link href={menu.href} key={menu.title} passHref prefetch={false}>
                <ListItem
                  onClick={toggleMainMenu}
                  title={menu.title}
                  button
                  component="a"
                  style={{
                    paddingTop: 0,
                    paddingBottom: 0,
                  }}
                >
                  {ListText}
                </ListItem>
              </Link>
            );
          }

          if (menu.onClick) {
            return (
              <ListItem
                key={menu.title}
                onClick={async () => {
                  await menu.onClick();
                  toggleMainMenu();
                }}
                title={menu.title}
                button
                component="a"
                style={{
                  paddingTop: 0,
                  paddingBottom: 0,
                }}
              >
                {ListText}
              </ListItem>
            );
          }

          return <></>;
        })}
      </List>
    </Drawer>
  );
};
export default UserDrawerDisplay;
