import { Gbp, GbpPromise } from 'apiclient';
import { Card } from 'types/user';

const gbp = new Gbp();

export interface createCardTokenProps {
  rememberCard: boolean;
  card: Card;
}

export interface createCardTokenReturn {
  rememberCard: boolean;
  card: Card & { token: string };
}

const createCardToken = async (
  props: createCardTokenProps,
): GbpPromise<createCardTokenReturn> => {
  return gbp.request({
    method: 'POST',
    url: '/v1/tokens',
    data: props,
  });
};
export default createCardToken;
