import * as React from 'react';
import Cookies from 'js-cookie';

export type CookiesConsentStateType = {
  cookiesConsentPerm: string[] | undefined;
  openCookiesConsentDialog: boolean;
  surpressCookiesConsentDialog: boolean;
};

export type CookiesConsentSetterType = {
  setCookiesConsentPerm: React.Dispatch<React.SetStateAction<string[] | undefined>>;
  setOpenCookiesConsentDialog: React.Dispatch<React.SetStateAction<boolean>>;
  setSurpressCookiesConsentDialog: React.Dispatch<React.SetStateAction<boolean>>;
};

export const defaultCookiesConsentSetter = {
  setCookiesConsentPerm: (): null => null,
  setOpenCookiesConsentDialog: (): null => null,
  setSurpressCookiesConsentDialog: (): null => null,
};

export const useCookiesConsent = (): {
  state: CookiesConsentStateType;
  setter: CookiesConsentSetterType;
} => {
  const ccPerm = Cookies.get('cc_perm')?.split(',');
  const [cookiesConsentPerm, setCookiesConsentPerm] = React.useState(ccPerm);
  const [openCookiesConsentDialog, setOpenCookiesConsentDialog] = React.useState(false);
  const [surpressCookiesConsentDialog, setSurpressCookiesConsentDialog] = React.useState(false);

  return {
    state: {
      cookiesConsentPerm,
      openCookiesConsentDialog,
      surpressCookiesConsentDialog,
    },
    setter: {
      setCookiesConsentPerm,
      setOpenCookiesConsentDialog,
      setSurpressCookiesConsentDialog,
    },
  };
};
