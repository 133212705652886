import { Caffe, CaffePromise } from 'apiclient';

const caffe = new Caffe();

export type checkDuplicateUserProps = string;
export type checkDuplicateUserReturn = boolean;

const checkDuplicateUser = async (
  email: checkDuplicateUserProps,
): CaffePromise<checkDuplicateUserReturn> => {
  return caffe.request({
    method: 'GET',
    url: '/api/method/caff.e.user.check_duplicate_user',
    params: { email },
  });
};
export default checkDuplicateUser;
