import { styled } from '@mui/material/styles';
import { FormHelperText } from '@mui/material';

const FormHelperTextStyled = styled(FormHelperText)(({ theme }) => ({
  fontWeight: theme.typography.fontWeightLight,
  marginTop: 0,
  marginLeft: '0.1rem',
  lineHeight: 1.3,
}));
export default FormHelperTextStyled;
