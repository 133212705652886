import useSWR, { KeyedMutator } from 'swr';

import { userInfo, userInfoReturn } from 'api';

export type useUserInfoReturn = {
  isLoading: boolean;
  isError: boolean;
  isValidating: boolean;
  data?: userInfoReturn;
  mutate: KeyedMutator<userInfoReturn>;
};

const useUserInfo = (args: {
  initialData?: userInfoReturn;
}): useUserInfoReturn => {
  const { initialData } = args;

  const { data, error, mutate, isValidating } = useSWR(
    {
      ...{ api: 'userInfo' },
    },
    userInfo,
    {
      fallbackData: initialData,
      revalidateIfStale: true,
      revalidateOnMount: true,
      revalidateOnFocus: true,
      revalidateOnReconnect: true,
      refreshInterval: 0,
      // one minute
      dedupingInterval: 60000,
    },
  );

  return {
    isLoading: !error && !data,
    isError: error,
    isValidating,
    data,
    mutate,
  };
};
export default useUserInfo;
