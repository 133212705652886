import * as React from 'react';
import { AppBar, Container, Box } from '@mui/material';

import WebHeader from '../WebHeader';
import MobileHeader from '../MobileHeader';

const Header = (): JSX.Element => {
  const sections = [
    { title: 'Shop', href: '/shop' },
    { title: 'Roaster', href: '/roaster' },
    { title: 'Blog', href: '/blog' },
    { title: 'Coffee Event', href: '/coffee-event' },
    { title: 'About', href: '/about' },
  ];

  return (
    <>
      <Container maxWidth="xl" disableGutters>
        <AppBar
          position="static"
          elevation={0}
          sx={{ backgroundColor: 'transparent' }}
        >
          <Box sx={{ display: { xs: 'none', md: 'block' } }}>
            <WebHeader sections={sections} />
          </Box>
          <Box sx={{ display: { xs: 'block', md: 'none' } }}>
            <MobileHeader sections={sections} />
          </Box>
        </AppBar>
      </Container>
    </>
  );
};
export default Header;
