import qs from 'qs';
import type { NextRouter } from 'next/router';

export type parseParams = string;
export type parseReturn = Record<string, any>;
export const parse = (string: parseParams): parseReturn =>
  qs.parse(string, {
    comma: true,
    // https://github.com/ljharb/qs/issues/91
    decoder(value) {
      // parse numbers from string to number
      if (/^(\d+|\d*\.\d+)$/.test(value)) {
        return parseFloat(value);
      }
      return value;
    },
  });

export type stringifyParams = Record<string, any>;
export type stringifyReturn = string;
export const stringify = (object: stringifyParams): stringifyReturn =>
  qs.stringify(object, {
    arrayFormat: 'comma',
    encodeValuesOnly: true,
  });

export const removeQueryParamsFromRouter = (
  router: NextRouter,
  removeList: string[] = [],
): void => {
  // If removeList is empty, the function removes all params from url.
  // https://stackoverflow.com/a/70186040/8112804

  if (removeList.length > 0) {
    removeList.forEach((param) => delete router.query[param]);
  } else {
    // Remove all
    Object.keys(router.query).forEach((param) => delete router.query[param]);
  }
  router.replace(
    {
      pathname: router.pathname,
      query: router.query,
    },
    undefined,
    /**
     * Do not refresh the page
     */
    { shallow: true },
  );
};
