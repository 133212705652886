import * as React from 'react';
import { Modal, Box } from '@mui/material';

import { ModalContainer } from 'components/shared';
import {
  SignupModal,
  ForgotPassModal,
  LoginModal,
  ResetPassModal,
  NewLineUserModal,
} from 'components/header';
import { UserModalStateType, UserModalSetterType } from './UserModalContext';

type UserModalProps = UserModalStateType & UserModalSetterType;
const UserModal = (props: UserModalProps): JSX.Element => {
  const {
    userModalState: { type: modalType, detail: modalDetail },
    userModalDispatch,
  } = props;

  return (
    <Modal
      open={modalType !== 'None'}
      onClose={() => {
        if (!modalDetail?.disableClose) {
          // disable close modal on ResetPass modal and /user/login router
          userModalDispatch({ type: 'close' });
        }
      }}
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        padding: '1rem',
      }}
    >
      <ModalContainer>
        <Box sx={{ display: modalType === 'Login' ? 'block' : 'none' }}>
          <LoginModal
            fromCart={modalDetail?.fromCart}
            onLoginSucceed={modalDetail?.onLoginSucceed}
          />
        </Box>
        <Box sx={{ display: modalType === 'Signup' ? 'block' : 'none' }}>
          <SignupModal fromCart={modalDetail?.fromCart} />
        </Box>
        <Box sx={{ display: modalType === 'Reset' ? 'block' : 'none' }}>
          <ForgotPassModal />
        </Box>
        <Box sx={{ display: modalType === 'ResetPass' ? 'block' : 'none' }}>
          <ResetPassModal />
        </Box>
        <Box sx={{ display: modalType === 'NewLineUser' ? 'block' : 'none' }}>
          <NewLineUserModal />
        </Box>
      </ModalContainer>
    </Modal>
  );
};
export default React.memo(UserModal);
