import { Caffe, CaffEmptyPromise } from 'apiclient';
import { AxiosRequestConfig } from 'axios';
import { CoffeeGrind } from 'types/product';

const caffe = new Caffe();

export interface removeItemsProps {
  itemList: { product: string; grindLevel: CoffeeGrind }[];
  axiosConfig?: AxiosRequestConfig;
}

export type removeItemsReturn = CaffEmptyPromise;

const removeItems = async (props: removeItemsProps): CaffEmptyPromise => {
  // will use cart_id from cookies and session user
  const { axiosConfig, ...data } = props;

  // remap data from camelCase to snake_case
  const backendItemList: {
    product: string;
    // eslint-disable-next-line camelcase
    grind_level: CoffeeGrind;
  }[] = [];
  data.itemList.forEach((item) => {
    backendItemList.push({
      product: item.product,
      grind_level: item.grindLevel,
    });
  });

  return caffe.request({
    ...{
      method: 'PUT',
      url: '/api/method/caff.e.cart.remove_items',
      data: {
        // due to frappe some sort of bugs
        // we need to wrap the data in an array
        // to preserve data
        item_list: [backendItemList],
      },
      withCredentials: true,
    },
    ...axiosConfig,
  });
};
export default removeItems;
