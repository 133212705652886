import * as React from 'react';
import { useFormik, FormikValues } from 'formik';
import * as Yup from 'yup';
import { Typography, ButtonBase } from '@mui/material';
import posthog from 'posthog-js';

import { useGetCart, useUserInfo } from 'composables';
import { TextField, StyledButton, PasswordField, FieldBox } from 'components/shared';
import { GlobalModalSetter } from 'components/modal';
import { login } from 'api';
import SocialLogin from '../SocialLogin';

type LoginFormProps = {
  onLoginSucceed?: () => void;
  fromCart?: boolean;
};
const LoginForm = (props: LoginFormProps): JSX.Element => {
  const { onLoginSucceed, fromCart } = props;
  const { userModalDispatch, setSurpressCookiesConsentDialog } =
    React.useContext(GlobalModalSetter);
  const { mutate: mutateUserInfo } = useUserInfo({});
  const { mutate: mutateCart } = useGetCart({});

  type LoginFormikValues = {
    formError?: string;
  } & FormikValues;

  const formik = useFormik<LoginFormikValues>({
    initialValues: {
      email: '',
      password: '',
    },
    validationSchema: Yup.object({
      email: Yup.string().email('Invalid email address').required('Required'),
      password: Yup.string().required('No password provided'),
    }),

    onSubmit: async (v, { setSubmitting, setErrors }) => {
      setSubmitting(true);

      try {
        const r = await login({
          usr: v.email,
          pwd: v.password,
        });

        setSubmitting(false);
        userModalDispatch({ type: 'close' });

        mutateCart();
        mutateUserInfo();
        posthog.identify(v.email);
        setSurpressCookiesConsentDialog(false);

        if (onLoginSucceed) {
          onLoginSucceed();
        }
      } catch (err) {
        setSubmitting(false);
        setErrors({
          formError: 'Incorrect username or password',
          email: '-',
          password: '-',
        });
      }
    },
  });

  return (
    <form onSubmit={formik.handleSubmit}>
      <TextField
        name="email"
        label="Email Address"
        placeholder="Please enter your email address"
        onChange={formik.handleChange}
        onBlur={formik.handleBlur}
        value={formik.values.email}
        error={formik.touched.email && Boolean(formik.errors.email)}
        helperText={formik.touched.email && formik.errors.email !== '-' && formik.errors.email}
        disabled={formik.isSubmitting}
      />
      <PasswordField
        name="password"
        label="Password"
        placeholder="Please enter your password"
        onChange={formik.handleChange}
        onBlur={formik.handleBlur}
        value={formik.values.password}
        error={formik.touched.password && Boolean(formik.errors.password)}
        helperText={
          formik.touched.password && formik.errors.password !== '-' && formik.errors.password
        }
        disabled={formik.isSubmitting}
      />
      <FieldBox
        style={{ textAlign: 'right' }}
        sx={{
          '& p': {
            color: 'success.main',
            fontWeight: 'fontWeightLight',
          },
        }}
      >
        <ButtonBase
          onClick={() => {
            userModalDispatch({ dialogType: 'Reset' });
          }}
        >
          <Typography variant="body2">Forgot Password ?</Typography>
        </ButtonBase>
      </FieldBox>
      {formik.errors.formError ? (
        <FieldBox
          sx={{
            'paddingLeft': 1,
            '& p': {
              color: 'error.main',
              fontWeight: 'fontWeightLight',
            },
          }}
        >
          <Typography variant="body2">{formik.errors.formError}</Typography>
        </FieldBox>
      ) : null}
      <StyledButton
        variant="UserForm"
        type="submit"
        disabled={formik.isSubmitting}
        sx={{
          mt: 3,
        }}
      >
        Login
      </StyledButton>
      <SocialLogin type="login" fromCart={fromCart} />
    </form>
  );
};
export default LoginForm;
