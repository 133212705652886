import { Caffe, CaffePromise } from 'apiclient';

const caffe = new Caffe();

export type changePassProps =
  | {
      newPassword: string;
      key: string;
      oldPassword?: never;
    }
  | {
      newPassword: string;
      key?: never;
      oldPassword: string;
    };

export type changePassReturn = {
  email: string;
};

const changePassword = async (
  props: changePassProps,
): CaffePromise<changePassReturn> => {
  const { newPassword, key, oldPassword } = props;
  return caffe.request({
    method: 'POST',
    url: '/api/method/caff.e.auth.email.update_pass',
    data: {
      new_password: newPassword,
      key,
      old_password: oldPassword,
    },
  });
};
export default changePassword;
