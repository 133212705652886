import * as React from 'react';

import { Modal, Button, Box, Typography } from '@mui/material';
import { useRouter } from 'next/router';
import { removeQueryParamsFromRouter } from 'utils/queryString';

import { LogoCaffeinnne } from 'assets/Branding';
import { SpecialOffer } from 'assets/Icon';
import { ModalContainer } from 'components/shared';
import { getImgUrlReplaceBe } from 'utils/image';
import { useCouponDetail } from 'composables';
import {
  CouponModalStateType,
  CouponModalSetterType,
} from './CouponModalContext';
import { CookiesConsentStateType } from '../CookiesConsent/CookiesConsentContext';

export type CouponModalProps = CouponModalStateType &
  CouponModalSetterType &
  CookiesConsentStateType;

type CouponCodeProps = {
  header: string;
  couponImgPath: string;
  description: string;
};

const CouponCode = (props: CouponCodeProps): JSX.Element => {
  const { header, couponImgPath, description } = props;
  return (
    <Box
      sx={{
        marginY: 1.5,
      }}
    >
      <Typography
        variant="subtitle1"
        fontFamily="fontFamilySansSerif"
        fontWeight="bold"
        color="#636e62"
      >
        {header}
      </Typography>
      <Box sx={{ marginX: 1 }}>
        <Box
          component="img"
          sx={{
            borderRadius: '15px',
            marginTop: 1,
          }}
          src={getImgUrlReplaceBe({
            imgPath: couponImgPath,
            encodeUri: true,
          })}
          width="100%"
          minHeight="150px"
          alt={header}
        />
        <Typography
          color="#636e62"
          sx={{
            paddingLeft: '0.5rem',
          }}
        >
          {description}
        </Typography>
      </Box>
    </Box>
  );
};

const CouponModal = (props: CouponModalProps): JSX.Element => {
  const {
    openCouponModalDialog,
    setOpenCouponModalDialog,
    cookiesConsentPerm,
    openCookiesConsentDialog,
    surpressCookiesConsentDialog,
  } = props;

  const [passCookies, setPassCookies] = React.useState(false);

  const router = useRouter();
  const { query, isReady } = router;

  const {
    isLoading: couponIsLoading,
    isError: couponIsError,
    data: couponData,
  } = useCouponDetail({
    sCn: query.s_cn,
    sCo: query.s_co,
    pCn: query.p_cn,
  });

  React.useEffect(() => {
    if (surpressCookiesConsentDialog) {
      setPassCookies(false);
    } else {
      setPassCookies(Boolean(cookiesConsentPerm));
    }
  }, [
    cookiesConsentPerm,
    openCookiesConsentDialog,
    surpressCookiesConsentDialog,
  ]);

  React.useEffect(() => {
    if (typeof query?.dcm === 'string' && query.dcm === 'y') {
      const openDialog =
        passCookies &&
        !couponIsLoading &&
        !couponIsError &&
        typeof couponData === 'object' &&
        Object.keys(couponData).length > 0;
      setOpenCouponModalDialog(openDialog);
      if (openDialog) {
        removeQueryParamsFromRouter(router, ['dcm']);
      }
    }
  }, [
    passCookies,
    setOpenCouponModalDialog,
    isReady,
    query,
    couponIsLoading,
    couponIsError,
    couponData,
  ]);

  return (
    <Modal
      disableAutoFocus
      open={openCouponModalDialog}
      onClose={() => setOpenCouponModalDialog(false)}
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <ModalContainer
        maxWidth="xs"
        sx={{
          marginTop: '80px',
          maxHeight: '80vh',
          backgroundColor: '#e7f4e4',
          borderRadius: '15px',
          position: 'relative',
          display: 'inline-block', // Will size according to content
        }}
      >
        <Box
          sx={{
            content: '""', // To create the pseudo-element
            display: 'block',
            position: 'absolute',
            top: '-20px',
            left: 0,
            right: 0,
            bottom: '-20px',
            backgroundColor: '#bcc9ac',
            borderRadius: '15px',
            transform: 'rotate(3deg)',
            zIndex: -1,
          }}
        />
        <Box
          sx={{
            position: 'absolute',
            top: '0',
            right: '110px',
            transform: 'translate(50%,-50%)',
          }}
        >
          <SpecialOffer height="125px" width="125px" />
        </Box>
        <Box sx={{ marginBottom: 1, marginTop: 1 }}>
          <Box>
            <LogoCaffeinnne width="60px" height="40px" />
          </Box>
          <Box
            sx={{
              overflowY: 'auto',
              maxHeight: 'calc(80vh - 200px)',
            }}
          >
            {couponData?.shopCouponDetail ? (
              <CouponCode
                header="Shop Coupon"
                couponImgPath={couponData.shopCouponDetail.img}
                description={couponData.shopCouponDetail.detail}
              />
            ) : (
              <></>
            )}
            {couponData?.platformCouponDetail && couponData.shopCouponDetail ? (
              <Box
                component="hr"
                sx={{
                  marginY: 3,
                  marginX: 4,
                  border: 'none',
                  borderTop: '2px solid #636e62',
                }}
              />
            ) : (
              <></>
            )}
            {couponData?.platformCouponDetail ? (
              <CouponCode
                header="Platform Coupon"
                couponImgPath={couponData.platformCouponDetail.img}
                description={couponData.platformCouponDetail.detail}
              />
            ) : (
              <></>
            )}
          </Box>
          <Button
            fullWidth
            variant="contained"
            disableElevation
            size="large"
            sx={{
              marginTop: 1,
              backgroundColor: '#636e62',
              fontWeight: 'bold',
              marginBottom: 1,
              borderRadius: '15px',
            }}
            onClick={() => setOpenCouponModalDialog(false)}
          >
            <Typography
              variant="h6"
              fontFamily="fontFamilySansSerif"
              fontWeight="bold"
            >
              Shop Now
            </Typography>
          </Button>
          <Typography
            variant="caption"
            sx={{
              color: '#636e62',
            }}
          >
            Capture the screen, then apply the code during checkout.
          </Typography>
        </Box>
      </ModalContainer>
    </Modal>
  );
};
export default CouponModal;
