import * as React from 'react';
import { Box } from '@mui/material';
import Typography from '@mui/material/Typography';
import { useRouter } from 'next/router';

import { transparentTagline } from 'styles/route-layout';

const Sep = (): JSX.Element => {
  return (
    <Box component="span" sx={{ px: '4px' }}>
      |
    </Box>
  );
};

type TagProps = {
  children: string;
};
const Tag = (props: TagProps): JSX.Element => {
  const { children } = props;
  return (
    <Box component="span" sx={{ whiteSpace: 'nowrap' }}>
      {children}
    </Box>
  );
};

const Tagline = (): JSX.Element => {
  const router = useRouter();

  return (
    <Typography
      variant="subtitle2"
      component="p"
      sx={{
        lineHeight: 1.3,
        lineBreak: 'anywhere',
      }}
      color={
        transparentTagline.has(router.route)
          ? 'common.white'
          : 'common.darkGrey'
      }
    >
      <Tag>Good Coffee</Tag>
      <Sep />
      <Tag>Good Beans</Tag>
      <Sep />
      <Tag>Good Roasters</Tag>
    </Typography>
  );
};
export default Tagline;
