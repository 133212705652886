import { styled } from '@mui/material/styles';
import { InputBase } from '@mui/material';

const InputBaseStyled = styled(InputBase)(({ theme }) => ({
  '&.MuiInputBase-root': {
    marginTop: theme.spacing(2.5),
    fontWeight: theme.typography.fontWeightLight,
    borderRadius: 4,
    border: '1px solid transparent',
    backgroundColor: theme.palette.common.white,
  },
  '&.Mui-error': {
    borderRadius: 4,
    border: '1px solid red',
  },
  '& .MuiInputBase-input': {
    borderRadius: 4,
    position: 'relative',
    fontSize: 16,
    padding: '6px 10px',
    transition: theme.transitions.create(['border-color', 'box-shadow']),
  },
}));
export default InputBaseStyled;
