import { Caffe, CaffePromise } from 'apiclient';

const caffe = new Caffe();

export interface userInfoReturn {
  displayName?: string;
  avatar?: string;
  email?: string;
}

const userInfo = async (): CaffePromise<userInfoReturn> => {
  return caffe.request({
    method: 'GET',
    url: '/api/method/caff.e.get_user_info',
    withCredentials: true,
  });
};
export default userInfo;
