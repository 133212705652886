import * as React from 'react';
import { Box } from '@mui/material';

import UserModalHeader from '../UserModalHeader';
import ResetPassForm from '../ResetPassForm';
import ResetPassFeedback from '../ResetPassFeedback';
import ResetPassContext from '../ResetPassContext';

const ResetPassModal = (): JSX.Element => {
  const [display, setDisplay] = React.useState('Form');
  const [email, setEmail] = React.useState('');

  return (
    <ResetPassContext.Provider value={{ display, setDisplay, email, setEmail }}>
      <UserModalHeader title="Reset Password" />
      <Box sx={{ display: display === 'Form' ? 'block' : 'none' }}>
        <ResetPassForm />
      </Box>
      <Box sx={{ display: display === 'Feedback' ? 'block' : 'none' }}>
        <ResetPassFeedback />
      </Box>
    </ResetPassContext.Provider>
  );
};
export default ResetPassModal;
