import {
  Button as MuiButton,
  ButtonProps as MuiButtonProps,
  TypographyVariant,
} from '@mui/material';
import { styled } from '@mui/material/styles';

export type ButtonProps = {
  fontFamily?: 'serif' | 'sansSerif';
  fontSize?: TypographyVariant;
} & MuiButtonProps;
const Button = styled(MuiButton, {
  shouldForwardProp: (prop: keyof ButtonProps) => prop !== 'fontFamily',
})<ButtonProps>(({ theme, fontFamily, fontSize }) => {
  const style: Pick<React.CSSProperties, 'fontSize' | 'fontFamily'> = {};

  if (typeof fontFamily === 'string') {
    if (fontFamily === 'serif') {
      style.fontFamily = theme.typography.fontFamilySansSerif;
    } else if (fontFamily === 'sansSerif') {
      style.fontFamily = theme.typography.fontFamilySerif;
    }
  }

  if (typeof fontSize === 'string') {
    style.fontSize = theme.typography[fontSize].fontSize;
  }

  return style;
});
export default Button;
