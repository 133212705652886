import axios, { AxiosRequestConfig, AxiosInstance } from 'axios';

export type GbpResultCode = { resultCode: string };
export type GbpResponse<T> = T & GbpResultCode;

export type GbpPromise<T> = Promise<GbpResponse<T> | Error>;
export type GbpEmptyPromise = Promise<GbpResultCode | Error>;

export const GbpBaseUrl = process.env.NEXT_PUBLIC_GBP_URL as string;
export const getGbpUrl = (url: string): string => `${GbpBaseUrl}${url}`;

// https://doc.gbprimepay.com
class Gbp {
  axios: AxiosInstance;
  resultCode: { [key: string]: string };

  constructor() {
    this.axios = axios.create({
      baseURL: GbpBaseUrl,
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
      },
      auth: {
        username: process.env.NEXT_PUBLIC_GBP_PUBLIC_KEY as string,
        password: '',
      },
    });

    // last update: 31/08/2021
    this.resultCode = {
      '00': 'Success',
      '01': 'Refer to Card Issuer, Give cardholder contacts issuer bank',
      '02': 'Invalid data',
      '03': 'Invalid Merchant ID, Please contact GBPrimePay',
      '05': 'Do Not Honour, Cardholder input invalid card information. Ex. Expiry date, CVV2 or card number. Give cardholder contacts issuer bank',
      '12': 'Invalid Transaction, Please contact GBPrimePay',
      '13': 'Invalid Amount, Payment amount must more than 0.1',
      '14': 'Invalid Card Number, Please check all digits of card no',
      '17': 'Customer Cancellation, Customers click at cancel button in payment page when they make transaction. Customers have to make new payment transaction',
      '19': 'Re-enter Transaction, Duplicate payment. Please contact GBPrimePay',
      '30': 'Format Error, Transaction format error. Please contact GBPrimePay',
      '41': 'Lost Card, Lost Card and Cardholder give up',
      '43': 'Stolen Card, Stolen Card and Cardholder give up',
      '50': 'Invalid Payment Condition, Ex. Session time out or invalid VbV Password : ask cardholders to try ma again and complete transaction within 15 minutes with correct card information',
      '51': 'Insufficient Funds, Not enough credit limit to pay. Please contact issuer',
      '54': 'Expired Card, Cardholder key in invalid expiry date',
      '55': 'Wrong Pin, Wrong Pin',
      '58': 'Transaction not Permitted to Terminal, Issuer does not allow to pay with debit card (Visa Electron, Mastercard Electron)',
      '91': 'Issuer or Switch is Inoperative, Issuer system is not available to authorize payment',
      '94': 'Duplicate Transmission, Please inform GBPrimePay to investigate',
      '96': 'System Malfunction, Issuer bank system can not give a service',
      'xx': 'Transaction Timeout,Can not receive response code from issuer with in the time limit',
    };
  }

  request<T = unknown>(params: AxiosRequestConfig): GbpPromise<T> {
    return new Promise((resolve, reject) => {
      this.axios(params)
        .then((response) => {
          const { resultCode } = response.data;
          // gbp api return with resultCode response
          if (resultCode === '00') {
            resolve(response.data);
          } else {
            reject(
              new Error(
                `${resultCode}${
                  resultCode in this.resultCode
                    ? ` - ${this.resultCode[resultCode]}`
                    : ''
                }`,
              ),
            );
          }
        })
        .catch((error) => {
          if (error.isAxiosError) {
            // handle axios error response
            // the request that was made to server
            // but server responded with a status code
            // that falls out of the range of 2xx
            reject(
              new Error(`${error.response.status} - ${error.response.headers}`),
            );
          } else {
            // handle error that is not axios error response
            reject(new Error(error.message));
          }
        });
    });
  }
}
export default Gbp;
