/* eslint-disable camelcase */
import { useRouter } from 'next/router';
import posthog from 'posthog-js';
import { useEffect, useState } from 'react';
import Cookies from 'js-cookie';

export const usePostHog = (enable: boolean, name?: string): void => {
  const isDev = process.env.NODE_ENV === 'development';

  const apiKey = process.env.NEXT_PUBLIC_PH_TRACKING_ID as string;
  const config: posthog.Config = {
    api_host: process.env.NEXT_PUBLIC_PH_API_HOST,
    // disable in development
    loaded: (ph) => {
      if (isDev) {
        ph.opt_out_capturing();
      }
    },
  };

  const router = useRouter();
  const { isReady, query } = router;
  const { c_n, c_o } = query;

  const [phInit, setPhInit] = useState(false);

  const phDisableConfig = Boolean(process.env.NEXT_PUBLIC_PH_DISABLE) || false;

  // eslint-disable-next-line consistent-return
  useEffect((): (() => void) | undefined => {
    if (!phDisableConfig && enable) {
      // Init PostHog
      posthog.init(apiKey, config, name);
      setPhInit(true);
      const userId = Cookies.get('user_id');
      if (userId && userId !== 'Guest') {
        posthog.identify(userId);
      }

      // Track page views
      const handleRouteChange = () => posthog.capture('$pageview');
      router.events.on('routeChangeComplete', handleRouteChange);

      return () => {
        router.events.off('routeChangeComplete', handleRouteChange);
      };
    }
  }, [enable]);

  useEffect(() => {
    if (!phDisableConfig && enable && isReady) {
      if (c_n && c_o) {
        // set property on user level
        posthog.capture('$campaign_ref', {
          $set: { campaignName: c_n, campaignOwner: c_o },
          $set_once: { initialCampaignName: c_n, initialCampaignOwner: c_o },
        });
        // set property on event level
        // register event property to all event of this user in the next 1 days
        posthog.register(
          {
            campaignName: c_n,
            campaignOwner: c_o,
          },
          1,
        );
      }
    }
  }, [enable, isReady, c_n, c_o]);
};
