import * as React from 'react';
import dynamic from 'next/dynamic';

import {
  FeedbackModalSetterType,
  FeedbackModalStateType,
} from './FeedbackModalContext';

const DynamicFeedbackModal = dynamic(() => import('./FeedbackModal'));

type FeedbackModalDynaType = FeedbackModalStateType & FeedbackModalSetterType;
const FeedbackModalDyna = (props: FeedbackModalDynaType): JSX.Element => {
  const { feedbackModalOpen } = props;

  if (feedbackModalOpen) {
    return <DynamicFeedbackModal {...props} />;
  }
  return <></>;
};
export default React.memo(FeedbackModalDyna);
