import { Caffe, CaffePromise } from 'apiclient';

const caffe = new Caffe();

export type forgotPassProps = {
  email: string;
};

export type forgotPassReturn = {
  email: string;
};

const forgotPassword = async (
  props: forgotPassProps,
): CaffePromise<forgotPassReturn> => {
  const { email } = props;
  return caffe.request({
    method: 'POST',
    url: '/api/method/caff.e.auth.email.forgot_pass',
    data: {
      email,
    },
  });
};
export default forgotPassword;
